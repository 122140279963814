import React, {Component} from 'react';
import '../styles/Map.scss';

export default class Map extends Component {
    render() {
        return (
            <div className="map-container">
                <h2>Benwill Cabinets Proudly Serves GTA and Hamilton Regions</h2>
                <div style={{margin: "0 auto", width: "70%", overflow: "hidden", height: "fit-content"}}>
                    <iframe 
                        title="Benwill Cabinets" 
                        src="https://www.google.com/maps/d/u/2/embed?mid=1GymL4n0bL4aH0QQNsT4vrWcaWBgREEst" 
                        width="100%"
                        height="500"
                        frameBorder="0"
                        style={{border: "0", marginTop: "-59px"}} 
                        allowFullScreen=""></iframe>
                </div>
            </div>
        )
    }
}