import React from 'react';

const BasementSvg = ({
     style = {},
     fill = "#000",
     width = "100%",
     className = "",
     viewBox = "0 0 512 512.0005"
}) => (
    <svg  
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        className={`svg-icon ${className || ""}`}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill={fill} d="m504.804688 201.300781c-7.109376-5.921875-223.359376-186.128906-236-196.664062-7.417969-6.183594-18.191407-6.183594-25.609376 0l-236 196.664062c-4.558593 3.800781-7.195312 9.429688-7.195312 15.367188v275.332031c0 11.046875 8.953125 20 20 20h472c11.046875 0 20-8.953125 20-20v-275.332031c0-5.9375-2.636719-11.566407-7.195312-15.367188zm-32.804688 74.03125h-117.332031v-78h82.890625l34.441406 28.703125zm-432 .667969h38.667969v58.667969c0 11.046875 8.953125 20 20 20h58.667969v58.664062c0 11.046875 8.953124 20 20 20h58.664062v38.667969h-196zm236 196v-58.667969c0-11.042969-8.953125-20-20-20h-58.667969v-58.664062c0-11.046875-8.953125-20-20-20h-58.664062v-58.667969c0-11.046875-8.957031-20-20-20h-58.667969v-9.964844l216-180 133.558594 111.296875h-54.890625c-11.046875 0-20 8.957031-20 20v118c0 11.046875 8.953125 20 20 20h137.332031v156.667969zm0 0"/>
    </svg>
)

export default BasementSvg;