import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../styles/NotFound.scss';

export default class NotFound extends Component {
    render() {
        return ( 
            <div id="notfound">
                <Helmet>
                    <title>404 Not Found | Benwill Cabinets</title>
                </Helmet>
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>404</h1>
                    </div>
                    <h2>Oops! This Page Could Not Be Found</h2>
                    <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
                    <Link to="/">Go To Home Page</Link>
                </div>
            </div>
        );
    }
}