import React from 'react';
import { Link } from "react-router-dom";
import 'react-id-swiper/lib/styles/scss/swiper.scss';
import '../styles/Gallery.scss';
import { Event } from '../utils/tracking.js';
import galleryImages from "../utils/galleryImages.js";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

const CoverflowEffect = () => {
    return (
        <div id="gallery">
            <h2>GALLERY</h2>
            {/* <Swiper {...params}>    
                {galleryImages.map((image, i) => {
                        return (
                            <div className="swiper-slide" key={"image_" + i} style={{backgroundImage: `url(${image})` }}></div>
                        )
                })
                }       
            </Swiper> */}
            <Carousel 
                images={galleryImages} 
                isAutoPlaying={true}
                autoPlayInterval={4000}
                style={{ height: 500 }}                     
            />
            <div className="before-after-button-container">
                <Link to="/before-and-after-projects" id="button" onClick={()=> Event("Check out our Before/After projects from Home page", "Check out our Before/After projects from Home page was clicked", "Home page Gallery Check out our Before/After projects")}> 
                    Check Out Our Before & After 
                </Link>
            </div>
            <div className="contact-us">
                <a 
                    href="#contact" 
                    id="button" 
                    onClick={()=> Event("Home page Gallery section Contact us button", "Contact us button was clicked from Home page Gallery section", "Home page Gallery section Contact us button")}
                > 
                    <span> Contact Us </span> 
                </a>
            </div>
        </div>
    )
}

export default CoverflowEffect;
