const allProjectsCards = [ 
    {
        id: 46,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZvRcNVZiG9pNMNl9hfpJ2fO5BNdtF58AapX&linkpassword=undefined&size=1365x1025&crop=0&type=auto",
        description: "Before (1) and after (2-6) photos.  \n\nIt's 2023 now! No more popcorn ceilings! If you have it in your house we will be more that glad to come by and save your eyes from this disaster. \nAlso, we are big specialists in fitting huge things into a tiny space! Try it yourself and get shocked (in a good way ;) with the result! \nFull bedroom renovation with brand new \n- insulation, \n- drywall, \n- laundry zone and closet organizer, \n- plastering, \n- painting \n- and baseboards. \nBenwill, 1 step ahead of the game!"
    },
    {
        id: 45,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZdHcNVZ0AkxbrACB75E9z015uyog8LHMavy&linkpassword=undefined&size=1365x1025&crop=0&type=auto",
        description: "Before (1-4) and after (5-9) photos.  \n\nSmall things make a huge difference in your renovation. For real. \nAre you tired of your kids making a huge noise and not giving you any time to relax? \nMaybe it's time to consider additional soundproofing of your walls. Not enough? In this case, a 5.1 sound surround system will definitely help. Especially if you complement it with a high-quality projector and 120inch screen. Oh yes! Who needs a cinema anymore? \n\nFull bedroom renovation with a lot of small special elements in it: \n- new window, \n- new hi-end closet, \n- new 5.1 sound surround system with high definition projector and 120inch screen, \n- led ceiling light with a built-in fan, \n- full soundproofing of the walls, \n- electrical, insulation, drywall, plastering, painting and baseboards."
    },
    {
        id: 44,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZm0cNVZzuMzTIlBcdJ6gFIiSF9bLB1Az1iX&linkpassword=undefined&size=1025x1365&crop=0&type=auto",
        description: "Let's be honest with each other, we all want a nice big walk-in closet in our bedrooms. But not all of us are so lucky to have enough space for it. \nBenwill Cabinets will help you to find the best solution and utilize every single inch of available space and make you 1 step closer to your dream. \nOur closet solutions are not only stylish and modern looking, but also help you to optimize your space and bring more comfort to your life! \n\nBrand new closet complete in 3 days. Electrical and cabinet work."
    },
    {
        id: 43,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZr9WUVZnkQze0aLcP7GuzghGgcxhzuJHeJ7&linkpassword=undefined&size=1365x1025&crop=0&type=auto",
        description: "Before (1-2) and after (3-9) photos. \n\nEven small and old kitchen can be turned into attractive and comfortable space. \nObviously, we are not magicians, and cannot increase the square footage of the condo, but we can guide you how to visually make that illusion and improve your quality of life with new modern cabinet doors and quartz countertops. \nAnd don't forget about the backsplash! #BenwillMagic :) \n\n3 days turnaround."
    },
    {
        id: 42,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZLeWUVZviUEzvruyPph3iu9K80YkRqUYHX0&linkpassword=undefined&size=768x1024&crop=0&type=auto",
        description: "Before (1-4) and after (5-8) photos. \n\nSometimes all you need is to change boring white color and add a little bit elegance to your kitchen. \nProfessional Benwill renovation with noble black doors and gold handles together definately helps to achieve this goal. In addition to this, new backsplash tiles and improved lighting imlements new coocking traditions and generates right atmoshere for the entire family. \n\nDone in 4 days."
    }, 
    {
        id: 41,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZ0N3OVZuKeV8783ynfkulSsl6jIr4eixzrV&linkpassword=undefined&size=1368x1025&crop=0&type=auto",
        description: "Before (1-3) and after (4-5) photos. \n\nFrom 1920 directly to the 21st century! From old dated space to a fully renovated modern and budget friendly kitchen. \nNew cabinets with timeless classic shaker doors, laminate countertop and backsplash tiles. And the most important! New lights! \nDon't skip that important step if you really want to upgrade your dated kitchen. That makes a whole world of a difference! Benwill approved ;) \n\n1,5 weeks process."
    }, 
    {
        id: 40,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZ5A3OVZWb5YVjBknmLb6h8UeHHbuL1QU4NX&linkpassword=undefined&size=1024x768&crop=0&type=auto",
        description: "Before (1-2) and after (3-6) photos. \n\nAre you struggling with a storage space? Here is an interesting idea for you: improvise with understairs unused space. \nCombination of cedar wood and vinal 'stone' creates not just weather proof storage but definitely improve the outside look and curbside appeal! \nCheck this one out! \n\nDone in 3 days."
    },
    {
        id: 39,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZz12TVZeD8EBBxvjPjBQht5niV4qBUBT1aV&linkpassword=undefined&size=1365x1025&crop=0&type=auto",
        description: "Before (1-2) and after (3-6) photos. \n\nRefacing is the most affordable way to prepare your house to be listed on a market and significantly improve the chances to get it sold faster and at a higher price. \nHere is an example of how a one-day refacing project can assist you with your goals and make a perfect first impression on home buyers."
    },
    {
        id: 38,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZojbMVZo8OJC9D8SrkOCsv8m19ivhbuGiLX&linkpassword=undefined&size=1025x1365&crop=0&type=auto",
        description: "The core of beauty is simplicity. An elegant solution for limited spaces."
    },
    {
        id: 37,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZNhbMVZAiNna4yNp6Xo0Nu3QWupT8icCPwV&linkpassword=undefined&size=1025x1365&crop=0&type=auto',
        description: "Before (1) and after (2-3) photos. \n\nModern bedroom remodeling. \nComplete turnover of the old and dated plaster walls into properly insulated drywalled walls with \n- upgraded electrical, \n- soundproofing system, \n- hvac \n- and beautiful Egger « whitewood » closet under the sloped ceiling. \nEvery sq inch counts! \n\nIt only took us 8 days to make this customer really happy!"
    },
    {
        id: 36,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZ4QbMVZB6gIj01xgpfDKDOIHnvqSRpr7K97&linkpassword=undefined&size=1025x1365&crop=0&type=auto',
        description: "Before (1-2) and after (3-7) photos. \n\nComplete basement bedroom renovation project in GTA. A lot of things were done here including \n- new walls, \n- insulation, \n- subfloor and vinyl floor, \n- pot lights, \n- baseboards \n- and fresh coat of paint on the walls and ceiling to make a wow effect! \n New custom closet with classic sliding doors increase total storage capacity and makes this bedroom extremely charming! \n\nDone in less than 2 weeks."
    },
    {
        id: 35,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZ57shVZ7LdTquMmTAhD0fYU9joq7uVWjQAy&linkpassword=undefined&size=971x768&crop=0&type=auto',
        description: "Out of scope backyard project with \n- newly built gazebo with the smart features and outdoor home theatre; \n- patio stones/interlocking paving stones; \n- quartz fire table made of cinder blocks and finished with ledger stone panels on the sides and wood doors to access the inner part of the table; \n- relaxing fire pit area with cedar planter boxes for additional privacy; \n- decorative stone-tiles retaining wall; \n- new cedar garden shed; \n- renovated porch stairs. \n\nDone in 1 month."
    },
    {
        id: 34,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZlcWhVZY4610XRQOLHkYvxSb7OjpbdyL9ky&linkpassword=undefined&size=1025x1365&crop=0&type=auto',
        description: "Before (1-3) and after photos. \n\nModern setup with accent on black accessories and features. Complete bathroom renovation with \n- new wall colour, \n- new vinyl floor, \n- new tiles and baseboards. \n\n1 week turnaround."
    },
    {
        id: 33,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZOObzVZ7CdQiE3TYVBWLfBF5AWQILOYJWJy&linkpassword=undefined&size=1365x1025&crop=0&type=auto',
        description: "Before (1-7) and after photos. \n\nKitchen cabinets refacing with \n- new quartz countertop \n- and backsplash tiles. \nSpecial antique white color of the doors makes it look even more elegant. \n\nDone in 2 days only."
    },
    {
        id: 32,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZINp5VZj1qE0bNR0XjiIFWTp5iRkJ2pVle7&linkpassword=undefined&size=1024x1024&crop=0&type=auto',
        description: "Before (1-4) and after photos. \n\n- New flooring, \n- trims \n- and kitchen refacing. \n\n2 days turnaround."
    },
    {
        id: 31,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZtAp5VZuqGlHCnKDj5lYJlqiYXKCkXy8N5X&linkpassword=undefined&size=1024x1024&crop=0&type=auto',
        description: "Rental property upgrade: \n- new opening up the dining room wall, \n- kitchen refacing, \n- countertop, \n- some trim work \n- and flooring in the washroom. \n\nComplete in 5 days. "
    },
    {
        id: 30,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZ9Up5VZzOId92z9ttkqYp12E6Y4C8J8RIXX&linkpassword=undefined&size=1024x1024&crop=0&type=auto',
        description: "Before (1-4) and after photos. \n\nComplete 3-piece bathroom renovation. \n\n10 days turnaround."
    },
    {
        id: 29,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZ4Op5VZCRe5vsymo7QES1vXaiyiyBtA0zbk&linkpassword=undefined&size=1024x1024&crop=0&type=auto',
        description: "Before (1-4) and after photos. \n\nRenovation of antique house (build in 1929!!!). \n- Reopening and renovation of completely closed stares, \n- redesigning of an electrical panel box \n- and turning old ceiling tiles into drywall ceiling. \n\nDone in a 5 days."
    },
    {
        id: 28,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZ36p5VZCHrJRdHlmhB3JPCohH3eCkcuFfkV&linkpassword=undefined&size=920x768&crop=0&type=auto',
        description: "\n- New full set of vanity cabinetry with plumbing \n- and custom mirror cabinet. \n\n1 day turnaround."
    },
    {
        id: 27,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZ1tp5VZRK6w60EcEHRoQCsjkpAH9Y9x1vky&linkpassword=undefined&size=768x1024&crop=0&type=auto',
        description: "Before (1-2) and after photos. \n\nTwo days renovation project in Vaughan. \n- Full refacing, \n- new quartz countertop \n- and backsplash tiles."
    },
    {
        id: 26,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZxWi6XZmnTbX8iqrXYsK3fNW0BeuFSm3aGX&linkpassword=undefined&size=1365x1025&crop=0&type=auto',
        description: "Before (1-3) and after photos. \n\nFull reposition of the kitchen with \n- new quartz countertops, \n- quartz waterfalls, \n- under cabinets lights, \n- new tiles, \n- cabinets \n- and relocated electrical. \n\nDone within 3 weeks."
    },
    {
        id: 25,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZAXshVZ4SLQKxkeDSQVyu05wmSbLBUXVy3k&linkpassword=undefined&size=1025x1365&crop=0&type=auto',
        description: "Before (1-3) and after photos. \n\nFull bathroom remodel. \n- Plumbing relocation, \n- new heated flooring \n- and modern glass shower enclosure. \n\n2 weeks turnaround time."
    },
    {
        id: 24,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZ00shVZoqLj2oqVLomEDd6I3hFfDJdGnb3X&linkpassword=undefined&size=1025x1365&crop=0&type=auto',
        description: "Before (1-3) and after photos. \n\nModern master bedroom upgrade. \n- New closet with classic sliding doors, \n- new flooring, \n- paint, \n- electrical \n- and improved insulation. \n\nStart to finish - 10 days."
    },
    {
        id: 23,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZPfi6XZIVr1QEgML502e8tDQ6t48Ho4oDpk&linkpassword=undefined&size=1365x1032&crop=0&type=auto',
        description: "Before (1-3) and after photos. \n\nEntire first floor remodeling: \n- new walls, \n- light fixtures, \n- flooring, \n- fireplace \n- and more! \n\n2 weeks timeframe."
    },
    {
        id: 22,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZjji6XZag69LWb1KVhxn6Yvcd40D4HDaEdV&linkpassword=undefined&size=1365x1025&crop=0&type=auto',
        description: "Before (1-3) and after photos. \n\nStairs/railing renovation. \n\nDone within 3 days."
    },
    {
        id: 21,
        imageUrl: 'https://api.pcloud.com/getpubthumb?code=XZ9mBfXZign3wSwSe9pT9djkKOhvVjszPOlV&linkpassword=undefined&size=1365x1025&crop=0&type=auto',
        description: "Before (1-6) and after photos. \n\n\nFull bathroom renovation in 2 weeks."
    },
    {
        id: 20,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZEGDQXZ8omv8L217Bk6JatGsDWYBpMH8Oo7&linkpassword=undefined&size=1025x1365&crop=0&type=auto",
        description: "Before (1-3) and after photos. \n\nBig renovation project with \n- new flooring, \n- new accent wall with the fireplace \n- and TV made out of quartz. \n\n2 weeks lead time."
    },
    {
        id: 19,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZuoDQXZQldpKcsYllFlk3YuNgqVtLQRW7R7&linkpassword=undefined&size=1025x1365&crop=0&type=auto",
        description: "Before (1-2) and after photos. \n\nBrand new washroom with modern looking plumbing fixtures and new tiles. \n\nComplete within 4 days."
    },
    {
        id: 18,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZVcDQXZ1jQYDRxmDfHBuXOTxUxXN807U0Lk&linkpassword=undefined&size=1365x1025&crop=0&type=auto",
        description: "Before (1-9) and after photos. \n\nComplete washroom replacement. Full scope of work included a lot of \n- electrical, \n- plumbing \n- and tile work. \nHigh-end materials and great design solutions left customer very satisfied after the project completions. \n\n2 weeks turnaround."
    },
    {
        id: 17,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZNADQXZMCs2OiEU4sztqrAvN8bfDFhG2p5V&linkpassword=undefined&size=1365x1025&crop=0&type=auto",
        description: "Before (1-3) and after photos. \n\n- Kitchen cabinets refacing \n- and backsplash installation \n- also new ceiling light in the dining room. \n\nComplete within 1.5 days."
    },
    {
        id: 16,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZVyw7XZnvAj26ODKtmDIvYQb7xGJLdmyYuX&linkpassword=undefined&size=1365x1025&crop=0&type=auto",
        description: "Before (1-7) and after photos. \n\nFull kitchen replacement with white shaker cabinets. Also two closet organizers installed as well as pot lights. \n\n2 days turnaround."
    },
    {
        id: 15,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZYnvMkZ906EK06KYdShe3f4OYN72FrM0Kz7&linkpassword=undefined&size=756x1008&crop=0&type=auto",
        description: "Before (1-4) and after photos. \n\n- Full refacing \n- and granite/backsplash replacement within 5 days. \n- Microwave and air ducting have been changed as well. \n- Sink has been changed from 1.5 top-mount to double undermount sink."
    },
    {
        id: 14,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZEMOMkZWI9FhYTjsek6gRa6TvczCyrnn8Gk&linkpassword=undefined&size=1600x1200&crop=0&type=auto",
        description: "Before (1-4) and after photos. \n\nComplete renovation of the kitchen. \n- New backsplash, \n- new cabinets, \n- glass doors, \n- paining, \n- patching, \n- doors \n- and a lot of other things have been done in this house. \n\nComplete in 2 weeks."
    },
    {
        id: 13,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZmTOMkZvQXUMO0IqSVl3jc4fwAMTpbBusQ7&linkpassword=undefined&size=1200x1600&crop=0&type=auto",
        description: "Brand new laundry cabinets. \n\n1 day turnaround."
    },
    {
        id: 12,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZGTOMkZxGFiy1GswDH03ez30apydQ1wL0Uk&linkpassword=undefined&size=1600x1200&crop=0&type=auto",
        description: "Before (1-3) and after photos. \n\nTurning old oak cabinet doors into modern white shaker. Countertop has been changed as well. \n\n2 days turnaround."
    },
    {
        id:11,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZwPOMkZj4F9ItlcwKJuLzvxbJaKGL1xNeyV&linkpassword=undefined&size=1200x1600&crop=0&type=auto",
        description: "New quartz countertop as well as kitchen cabinet doors. \n\n2 days turnaround."
    },
    {
        id: 10,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZ5COMkZgAEje9cb5ybcocsIeXSfifGGgWPX&linkpassword=undefined&size=1920x1440&crop=0&type=auto",
        description: "Before (1-3) and after pictures of quick refacing of the kitchen from the old flat doors to modern chocolate raised. \n\n1 day turnaround."
    },
    {
        id: 9,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZBCOMkZcEUWyDbkQ4S4rvF0vXiLqjbd4AbX&linkpassword=undefined&size=1920x1440&crop=0&type=auto",
        description: "Before and after pictures of the renovated kitchen. First 4 photos are before. \nA lot of work have been done for this project. We have finished following things: \n- new drop celling, \n- drywall/patch/paint, \n- pot lights, \n- cabinets refacing, \n- undercabinet lighting. \n\n2 weeks turnaround."
    },
    {
        id: 8,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZrCOMkZo0CaBCPfvffWn1jsdUiIP7G50p87&linkpassword=undefined&size=1920x1440&crop=0&type=auto",
        description: "Amazing refacing job in GTA. We have turned old white flat doors into brand new white shaker. \n\n2 days turnaround."
    },
    {
        id: 7,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZFxOMkZrST6O0zAYNLQr93vwDjcS5biA6V7&linkpassword=undefined&size=576x1024&crop=0&type=auto",
        description: "New laundry cabinets white shaker. \n\n1 day turnaround."
    },
    {
        id: 6,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZBxOMkZOqYeaocGfEFz74a8MruprHx2FUTk&linkpassword=undefined&size=756x1008&crop=0&type=auto",
        description: "High end renovation in downtown Toronto. \n\n3 weeks turnaround."
    },
    {
        id: 5,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZnxOMkZiRyOQVFy4o7jrONxok7tSLt5ERBy&linkpassword=undefined&size=1920x1440&crop=0&type=auto",
        description: "“Smart home” kitchen and bathroom renovation with very limited space available. \n\n4 days turnaround."
    },
    {
        id: 4,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZAxOMkZeEDXkbIqM4H70NvYqg8nA42BAoSk&linkpassword=undefined&size=1920x1440&crop=0&type=auto",
        description: "Basement kitchen with \n- soft close bank of drawers \n- and special design column with all the related plumbing hidden inside. \n\n4 days turnaround."
    },
    {
        id: 3,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZjzw7XZ29ARxjOKdpYey1hezCKjRHoQqDy7&linkpassword=undefined&size=1365x1025&crop=0&type=auto",
        description: "Before (1-4) and after photos. \n\nSmall kitchen, \n- brand new cabinets \n- laminated countertop, \n- new backsplash tiles \n- and extended bar top. \n\n1 day turnaround."
    },
    {
        id: 2,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZ3WvMkZmtd6BQ2kcpSEG5AGSc1AKbpdDFtk&linkpassword=undefined&size=1920x1440&crop=0&type=auto",
        description: "Before (1-2) and after photos. \n\nComplete bathroom rebuild in 7 days. \n- Brand new flooring, \n- wall tiles, \n- vanity cabinet, \n- faucet, \n- mirror \n- and other accessories have been installed. \nA lot of plumbing and drywalling preparation work have been accomplished prior to the final installation."
    },
    {
        id: 1,
        imageUrl: "https://api.pcloud.com/getpubthumb?code=XZ9DvMkZxkFs0C7jl5STHeOcHz5YPuCmuk2y&linkpassword=undefined&size=1920x1440&crop=0&type=auto",
        description: "Before (1-5) and after photos. \n\n- Brand new luxury vinyl plank flooring, \n- baseboard \n- and quarter-round have been installed within 2 days while unit has been occupied with the minimum disturbance to the client."
    },
]

export default allProjectsCards;