import React from 'react';

const KitcheSvg = ({
     style = {},
     fill = "#000",
     width = "100%",
     className = "",
     viewBox = "0 0 512 512"
}) => (
    <svg 
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        className={`svg-icon ${className || ""}`}
        version="1.1" 
        id="Capa_1" 
        x="0px" 
        y="0px"
        xmlSpace="preserve"
    >
        <g id="SVGCleanerId_0">
            <g>
                <path fill={fill} d="M366.933,307.2H145.067c-4.71,0-8.533,3.814-8.533,8.533v187.733c0,4.719,3.823,8.533,8.533,8.533h221.867 c4.719,0,8.533-3.814,8.533-8.533V315.733C375.467,311.014,371.652,307.2,366.933,307.2z M358.4,494.933H153.6V324.267h204.8 V494.933z"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M366.933,307.2H145.067c-4.71,0-8.533,3.814-8.533,8.533v187.733c0,4.719,3.823,8.533,8.533,8.533h221.867 c4.719,0,8.533-3.814,8.533-8.533V315.733C375.467,311.014,371.652,307.2,366.933,307.2z M358.4,494.933H153.6V324.267h204.8 V494.933z"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M145.067,307.2H8.533c-4.71,0-8.533,3.814-8.533,8.533v187.733C0,508.186,3.823,512,8.533,512h136.533 c4.71,0,8.533-3.814,8.533-8.533V315.733C153.6,311.014,149.777,307.2,145.067,307.2z M136.533,494.933H17.067V324.267h119.467 V494.933z"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M503.467,307.2H366.933c-4.719,0-8.533,3.814-8.533,8.533v187.733c0,4.719,3.814,8.533,8.533,8.533h136.533 c4.719,0,8.533-3.814,8.533-8.533V315.733C512,311.014,508.186,307.2,503.467,307.2z M494.933,494.933H375.467V324.267h119.467 V494.933z"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M332.8,375.467H179.2c-4.71,0-8.533,3.814-8.533,8.533v85.333c0,4.719,3.823,8.533,8.533,8.533h153.6 c4.719,0,8.533-3.814,8.533-8.533V384C341.333,379.281,337.519,375.467,332.8,375.467z M324.267,460.8H187.733v-68.267h136.533 V460.8z"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="264.533" y="341.333" width="17.067" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="230.4" y="341.333" width="17.067" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="196.267" y="341.333" width="17.067" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="298.667" y="341.333" width="17.067" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="8.533" y="375.467" width="136.533" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="59.733" y="341.333" width="34.133" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="59.733" y="409.6" width="34.133" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="366.933" y="375.467" width="136.533" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="426.667" y="384" width="17.067" height="119.467"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="418.133" y="341.333" width="34.133" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="392.533" y="426.667" width="17.067" height="25.6"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="460.8" y="426.667" width="17.067" height="25.6"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M364.433,139.034L332.8,107.401V8.533c0-4.71-3.814-8.533-8.533-8.533H187.733c-4.71,0-8.533,3.823-8.533,8.533v98.867 l-31.633,31.633c-2.441,2.44-3.174,6.11-1.852,9.301c1.323,3.183,4.437,5.265,7.885,5.265h204.8c3.447,0,6.571-2.082,7.885-5.265 C367.607,145.143,366.874,141.474,364.433,139.034z M174.199,136.533l19.567-19.567c1.604-1.596,2.5-3.772,2.5-6.033V17.067 h119.467v93.867c0,2.261,0.896,4.437,2.5,6.033l19.567,19.567H174.199z"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M460.8,187.733c-18.825,0-34.133,15.309-34.133,34.133v8.533h17.067v-8.533c0-9.412,7.654-17.067,17.067-17.067 c9.412,0,17.067,7.654,17.067,17.067v93.867h17.067v-93.867C494.933,203.042,479.625,187.733,460.8,187.733z"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="460.8" y="273.067" width="25.6" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M247.467,256h-85.333c-4.71,0-8.533,3.814-8.533,8.533v51.2c0,4.719,3.823,8.533,8.533,8.533h85.333 c4.71,0,8.533-3.814,8.533-8.533v-51.2C256,259.814,252.177,256,247.467,256z M238.933,307.2h-68.267v-34.133h68.267V307.2z"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="136.533" y="273.067" width="25.6" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="247.467" y="273.067" width="25.6" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M145.067,0H8.533C3.823,0,0,3.823,0,8.533v102.4c0,4.71,3.823,8.533,8.533,8.533h136.533c4.71,0,8.533-3.823,8.533-8.533 V8.533C153.6,3.823,149.777,0,145.067,0z M136.533,102.4H17.067V17.067h119.467V102.4z"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="102.4" y="51.2" width="17.067" height="34.133"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M503.467,0H366.933c-4.719,0-8.533,3.823-8.533,8.533v102.4c0,4.71,3.814,8.533,8.533,8.533h136.533 c4.719,0,8.533-3.823,8.533-8.533V8.533C512,3.823,508.186,0,503.467,0z M494.933,102.4H375.467V17.067h119.467V102.4z"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="392.533" y="51.2" width="17.067" height="34.133"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="0" y="170.667" width="153.6" height="17.067"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="17.067" y="153.6" width="17.067" height="76.8"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M25.6,221.867c-14.114,0-25.6,11.486-25.6,25.6s11.486,25.6,25.6,25.6s25.6-11.486,25.6-25.6S39.714,221.867,25.6,221.867 z M25.6,256c-4.702,0-8.533-3.831-8.533-8.533c0-4.702,3.831-8.533,8.533-8.533c4.702,0,8.533,3.831,8.533,8.533 C34.133,252.169,30.302,256,25.6,256z"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="51.2" y="153.6" width="17.067" height="76.8"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M59.733,221.867c-14.114,0-25.6,11.486-25.6,25.6s11.486,25.6,25.6,25.6s25.6-11.486,25.6-25.6 S73.847,221.867,59.733,221.867z M59.733,256c-4.702,0-8.533-3.831-8.533-8.533c0-4.702,3.831-8.533,8.533-8.533 s8.533,3.831,8.533,8.533C68.267,252.169,64.435,256,59.733,256z"/>
            </g>
        </g>
        <g>
            <g>
                <react fill={fill} x="85.333" y="153.6" width="17.067" height="76.8"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={fill} d="M93.867,221.867c-14.114,0-25.6,11.486-25.6,25.6s11.486,25.6,25.6,25.6s25.6-11.486,25.6-25.6 S107.981,221.867,93.867,221.867z M93.867,256c-4.702,0-8.533-3.831-8.533-8.533c0-4.702,3.831-8.533,8.533-8.533 c4.702,0,8.533,3.831,8.533,8.533C102.4,252.169,98.569,256,93.867,256z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
)

export default KitcheSvg;
