import React, {Component} from 'react';
import '../styles/Warranty.scss';
import {Helmet} from "react-helmet";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default class WarrantyAQ extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <meta name="description" content="Warranty terms and conditions." />
                    <meta name="keywords" content="bathroom renovations, kitchen renovations, basement renovations, kitchen remodeling, renovation contractor, bathroom remodelling, kitchen reno, washroom renovations, home renovation contractors, small bathroom renovations, basement contractors, kitchen renovation near me, bathroom contractors near me, home remodeling contractors, home construction companies near me, home renovations near me, kitchen and bathroom renovations, residential contractor, kitchen renovation contractors near me, bedroom renovation, bathroom renovation contractors, kitchen cabinet renovation, basement remodels, small bathroom reno, budget bathroom renovation, kitchen renovation contractor, complete bathroom renovation, kitchen renovation company, basement renovation companies, kitchen renovation companies near me, kitchen construction company, basement bathroom contractors, basement bathroom renovation, bathroom renovations near me, renovation contractors near me, best kitchen remodelers near me, home renovation companies near me, kitchen bathroom renovations, house renovation contractor, bathroom contractors, kitchen contractors, warranty, renovation warranty." />
                    <title>Warranty | Benwill Cabinets</title>
                    <link rel="canonical" href="https://www.benwillcabinets.com/warranty" />
                    <meta property="og:title" content="Warranty | Benwill Cabinets" />
                    <meta property="og:description" content="" />
                    <meta property="og:url" content="https://www.benwillcabinets.com/warranty" />
                    <meta name="twitter:description" content="" />
                    <meta name="twitter:title" content="Warranty | Benwill Cabinets" />
                </Helmet>
                <div id="breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Warranty</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="warranty-container">
                    <h1>Warranty - Cabinets and Cabinet Doors</h1>
                    <h3>GENERAL TERMS AND CONDITIONS</h3>
                    <p>
                        Benwill Cabinets guarantees you the best quality of thermoplastic MDF doors against premature yellowing, delamination, and also 
                        both hidden and visible defects that will make them unsuitable for their intended use. This warranty is valid for a period of 
                        five (5) years from the date of installation. Repaired or replaced products are not subject to a new warranty period. 
                        The following constitutes the only warranty given by Benwill Cabinets on its products.
                    </p>
                    <h3>EXCLUSIONS</h3>                    
                        <div>This warranty does not apply:</div>  
                        <ol>                      
                            <li>When damages are caused by:     
                                <ul>                           
                                    <li>
                                        Misused, abused, burned, soiled, stained misshapen or otherwise damaged through negligence, accident or voluntary.
                                    </li>
                                    <li>Poor maintenance.</li>
                                    <li>
                                        Products that have been handled, modified, repaired or otherwise altered in any way by any person other than 
                                        Benwill Cabinets or one of it’s authorized representative.
                                    </li>
                                    <li>
                                        Exposure to a temperature over 85 degrees C (185F) and/or installed near a heating system or appliance without adequate insulation.
                                    </li>
                                    <li>Improper storage and intended use.</li>  
                                </ul>                              
                            </li>                        
                            <li>For outdoor installations.</li>
                            <li>The deterioration of the product resulting from normal wear and tear.</li>
                            <li>
                                Either the loss of use of the products during the time required to apply this warranty or to any other damages and 
                                inconveniences, both indirect and incidental, resulting from the use of the products.</li>
                        </ol>
                    <p>
                        To benefit from the services of this warranty, return the original product(s) and proof of purchase must be presented to 
                        Benwill Cabinets before the expiration date. Benwill Cabinets will then choose, at their discretion, the appropriate course of 
                        action whether it be to replace, repair, or credit any products. If the identical materials or products are not available at 
                        the time the warranty services is requested, Benwill Cabinets reserve the right to substitute them with materials and products 
                        of comparable quality, without being compelled to replace all of the products if only one or a few are defective.
                    </p>
                    <h1>Warranty - Installation / Renovation Services</h1>
                    <p>
                        All homes go through a period of settlement and as the seasons change, periods of expansion or contraction will occur. As a 
                        result, the home will experience minor material changes which are unavoidable and are considered normal. The Owner should also 
                        be aware that he/she is responsible for proper home maintenance such as maintaining contractor grades around the house. Damage 
                        caused by homeowner negligence, improper maintenance or changes, alterations or additions performed by anyone other than the 
                        Benwill Cabinets, his/her employees, or subcontractors directed by Benwill Cabinets is excluded from the warranty.
                    </p>
                    <p>
                        Limited 1-year warranty shall apply to all materials (except cabinets and cabinet doors) and labor supplied by Benwill Cabinets.
                    </p>
                    <p>
                        THIS LIMITED WARRANTY IS THE ONLY EXPRESS WARRANTY EXTENDED TO OWNER BY CONTRACTOR. ANY ITEM AND CONDITIONS NOT SPECIFICALLY 
                        COVERED BY THIS WARRANTY ARE EXCLUDED FROM COVERAGE AND IS THE RESPONSIBILITY OF OWNER. IT IS EXPRESSLY UNDERSTOOD THAT THIS 
                        LIMITED WARRANTY IS IN LIEU OF ANY AND ALL OTHER WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTY 
                        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND HABITABILITY IN NO EVENT SHALL CONTRACTOR BE LIABLE FOR ANY DAMAGES 
                        (CONSEQUENTIAL OR OTHERWISE) ARISING FROM ANY DEFECT IN ANY ITEM COVERED HEREUNDER. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS.
                    </p>
                </div>
            </>
        )
    }
}