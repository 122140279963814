import React from 'react';

const FloorSvg = ({
     style = {},
     fill = "#000",
     width = "100%",
     className = "",
     viewBox = "0 0 512 512"
}) => (
    <svg  
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        className={`svg-icon ${className || ""}`}
        version="1.1" 
        id="Capa_1" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        x="0px" 
        y="0px"
	    xmlSpace="preserve"
    >
        <g>
            <g>
                <path fill={fill} d="M502.737,0H9.265C4.148,0,0.001,4.148,0.001,9.264v139.175c0,5.116,4.148,9.264,9.264,9.264
                    c5.116,0,9.264-4.148,9.264-9.264v-47.865l50.242,50.242l-50.242,50.242v-22.355c0-5.116-4.148-9.264-9.264-9.264
                    c-5.116,0-9.264,4.148-9.264,9.264v324.034c0,5.116,4.148,9.264,9.264,9.264h493.471c5.116,0,9.264-4.148,9.264-9.264V9.264
                    C512.001,4.148,507.853,0,502.737,0z M478.601,18.528L430.128,67L381.65,18.528H478.601z M338.945,18.528L290.474,67
                    l-48.483-48.472H338.945z M305.042,107.777c3.618,3.618,9.484,3.618,13.1,0c3.618-3.618,3.618-9.483,0-13.101l-14.57-14.57
                    l56.723-56.723l127.44,127.433l-56.727,56.727l-91.869-91.869c-3.618-3.618-9.484-3.618-13.1,0c-3.618,3.618-3.618,9.483,0,13.101
                    l22.042,22.042l-56.725,56.727L163.919,80.106l56.723-56.723L305.042,107.777z M278.255,220.644L150.817,348.082l-56.725-56.725
                    l127.437-127.438L278.255,220.644z M199.293,18.528L150.821,67l-48.48-48.472H199.293z M18.529,18.528h41.11l-41.11,41.11V18.528z
                    M24.269,80.101l56.719-56.719l127.439,127.434L151.7,207.542L24.269,80.101z M18.529,227.252c0.021-0.02,0.043-0.037,0.063-0.057
                    l63.28-63.279l56.723,56.728L18.529,340.716V227.252z M18.529,381.652l50.245,50.245l-50.245,50.234V381.652z M33.392,493.472
                    l48.485-48.474l48.474,48.474H33.392z M24.269,361.187l56.723-56.729l127.435,127.435L151.7,488.619L24.269,361.187z
                    M173.052,493.472l48.477-48.477l48.477,48.477H173.052z M163.918,361.182l56.727-56.725l127.437,127.437l-56.725,56.727
                    L163.918,361.182z M312.706,493.472l48.477-48.477l48.477,48.477H312.706z M303.573,361.182l18.82-18.819
                    c3.618-3.618,3.618-9.483,0-13.101c-3.618-3.618-9.484-3.618-13.101,0l-18.82,18.82l-56.727-56.727l127.437-127.437l56.727,56.727
                    l-90.089,90.089c-3.618,3.618-3.618,9.483,0,13.101c3.618,3.618,9.484,3.618,13.101,0l19.378-19.378l127.435,127.439
                    L431.01,488.62L303.573,361.182z M493.471,493.472H452.36l41.111-41.111V493.472z M493.471,411.426l-50.241-50.24l50.241-50.253
                    V411.426z M493.397,284.804l-63.267,63.282l-56.73-56.729l120.071-120.072v113.452h0.001
                    C493.448,284.759,493.422,284.78,493.397,284.804z M493.473,130.343l-50.242-50.242l50.242-50.242V130.343z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
)

export default FloorSvg;