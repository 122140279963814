import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../styles/AllProjects.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import allProjectsCards from "../utils/allProjectsCards.js";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet';

export default class AllProjects extends Component {    
    render() {
        return (
            <div>
                <Helmet>
                    <title>Photo Gallery | Benwill Cabinets</title>
                    <link rel="canonical" href="https://www.benwillcabinets.com/before-and-after-projects" />
                    <meta name="description" content="Browse our before and after projects, featuring kitchen cabinets design and bathroom renovations. Call for free estimate." />
                    <meta name="keywords" content="bathroom renovations, kitchen renovations, basement renovations, kitchen remodeling, renovation contractor, bathroom remodelling, kitchen reno, washroom renovations, home renovation contractors, small bathroom renovations, basement contractors, kitchen renovation near me, bathroom contractors near me, home remodeling contractors, home construction companies near me, home renovations near me, kitchen and bathroom renovations, residential contractor, kitchen renovation contractors near me, bedroom renovation, bathroom renovation contractors, kitchen cabinet renovation, basement remodels, small bathroom reno, budget bathroom renovation, kitchen renovation contractor, complete bathroom renovation, kitchen renovation company, basement renovation companies, kitchen renovation companies near me, kitchen construction company, basement bathroom contractors, basement bathroom renovation, bathroom renovations near me, renovation contractors near me, best kitchen remodelers near me, home renovation companies near me, kitchen bathroom renovations, house renovation contractor, bathroom contractors, kitchen contractors, before and after projects, kitchen gallery, gallery, home after refacing, renovated kitchen, finished work, new cabinets." />
                    <meta property="og:title" content="Photo Gallery | Benwill Cabinets" />
                    <meta property="og:description" content="Browse our before and after projects, featuring kitchen cabinets design and bathroom renovations. Call for free estimate." />
                    <meta property="og:url" content="https://www.benwillcabinets.com/before-and-after-projects" />
                    <meta name="twitter:description" content="Browse our before and after projects, featuring kitchen cabinets design and bathroom renovations. Call for free estimate." />
                    <meta name="twitter:title" content="Photo Gallery | Benwill Cabinets" />
                </Helmet>
                <div id="breadcrumb">
                    <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Before & After</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="all-projects-container">
                    <h2>Before and After Renovation Photos</h2>
                    <Container>  
                        <Row>
                            {(allProjectsCards || []).map((projectCard) => {
                                return (
                                    <Link 
                                        key={projectCard.id} 
                                        to={`/before-and-after-projects/project?id=${projectCard.id}`}
                                    >
                                        <Col 
                                            lg={true}
                                        >
                                            <Card 
                                                style={{ width: '20rem' }}
                                            >
                                                <Card.Img 
                                                    variant="top" 
                                                    src={projectCard.imageUrl}                                                         
                                                />
                                                <Card.Body>
                                                    <Card.Text>
                                                        {projectCard.description}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Link>
                                )
                            })
                            }
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}