import React, {Component} from 'react';
import '../styles/Footer.scss';
import { Event } from '../utils/tracking.js';

export default class Footer extends Component {
    render() {
        return (
            <div className="footer-container">
                <p id="follow">Follow us on social media:
                    <a href="https://www.instagram.com/benwill_cabinets" target='_blank' rel="noopener noreferrer" onClick={()=> Event("Instagram from Footer", "Instagram from Footer was clicked", "home page Footer")}><i className="fab fa-instagram"></i></a>
                    <a href="https://www.facebook.com/benwill.cabinets" target='_blank' rel="noopener noreferrer" onClick={()=> Event("Facebook from Footer", "Facebook from Footer was clicked", "home page Footer")}><i className="fab fa-facebook-square"></i></a>
                </p>
                <div id="copyright">{`Copyright 2019-${new Date().getFullYear()} © Benwill Cabinets. All rights reserved.`}</div>
            </div>
        )
    }
}