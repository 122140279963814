import React, {Component} from 'react';
import '../styles/OurServices.scss';
import { Event } from '../utils/tracking.js';
import { Link } from "react-router-dom";

export default class OurServices extends Component {
    render() {
        return (
            <div className='main-container' id="our-services">
                <div className="our-services-container">
                    <h2>OUR SERVICES</h2>
                    <div className='service2'> 
                        <div className='service2-text-container'>
                            <h3><i className="fas fa-tools service-icons"></i>Kitchen and washroom complete renovation</h3>
                            <p>
                                <span className="display-none">Capabilities are endless when it comes to renovating your kitchen, we have the ability to design, as per your specifications 
                                or simply follow the existing layout of the original cabinetry.</span> 
                            </p> 
                            <p>
                                <span className="different">We provide free in house estimating. Our experienced team will 
                                provide satisfaction from beginning to the end of your renovation.</span> <span className="display-none">All our cabinets and drawers include soft close mechanisms, and we 
                                also offer a selection of countertops such as laminate in various colors and profiles, as well as granite and quartz at 
                                affordable prices. You will be amazed with our turn-around time.
                                </span>
                            </p>
                        </div>  
                    </div>
                    <div className='service1'> 
                        <div className='service1-text-container'>
                            <h3><i className="fas fa-door-open service-icons"></i>Are you limited on a budget but still want to get a fresh look to your kitchen? Consider <b>Door refacing</b>!</h3>
                            <p>
                                <span className="display-none">Benwill Cabinets offers an extensive selection of door styles to suit your budget. Many people misinterpret the refacing of doors. 
                                We replace the doors opposed to spray-painting them as others do.</span> 
                            </p>
                            <p>
                                <span className="different">Refacing of doors will change the entire look of your kitchen 
                                without removal of cabinets. This is the cheapest alternative to have your kitchen looking brand new.</span> <span className="display-none">We also provide soft close 
                                mechanisms for all doors installed as well as a selection of handles. Estimated time of completion – 1 day!</span>
                            </p>
                        </div>
                    </div>
                    <div className='service3'> 
                        <div className='service3-text-container'>
                            <h3><i className="fas fa-hammer service-icons"></i>Basement renovation</h3>
                            <p>
                                <span>
                                    Wondering what to do with your unfinished or old basement? Benwill Cabinets can offer hundreds of solutions that will work for your space 
                                    and will help to increase the value of your property. 
                                </span>
                            </p> 
                            <p>
                                Our team of specialists can turn your space into a gym, storage, home theatre or even 
                                    turn your basement into a separate unit to help make some extra income! 
                                    <a 
                                        href="#contact" 
                                        className='button' 
                                        onClick={()=> Event(`${window.location.pathname} page`, `Contact us today button was clicked from ${window.location.pathname} page Basement renovation section`, `${window.location.pathname} page Basement renovation section Contact us today button`)}
                                    > 
                                        <span className="contact-us"> Contact us </span> 
                                    </a>
                                    today to learn more!
                            </p>  
                        </div>
                    </div>
                    <div className='service4'> 
                        <div className='service4-text-container'>
                            <h3><i className="fas fa-hard-hat service-icons"></i>General contractor services</h3>
                            <span className="display-none">
                                Starting from concepts to design, and completing the installation, our highly experienced team of professionals can help you make your project a 
                                reality and within your budget.
                            </span>
                            <ul>
                                We provide following services:
                                    <li>plumbing</li>
                                    <li>electrical</li>
                                    <li>drywall and framing</li>
                                    <li>painting and patching</li>
                                    <li>carpentry</li>
                                    <li>tiling</li>
                                    <li>laminate and vinyl floors</li>
                                    <li>baseboards and crown moulding</li>
                                    <li>...and many more</li>
                            </ul>
                        </div>
                    </div>
                    <div className='service5'> 
                        <div className='service5-text-container'>
                            <h3><i className="fas fa-solid fa-lightbulb service-icons"></i>Lack of ideas?</h3>
                            <p>
                                <span>
                                    Bring new life to your home with stunning renovation inspiration! Are you tired of the same old look in 
                                    your living space?                                  
                                </span>                            
                            </p>
                            <p>
                                It's time for a change! Our collection of stunning home renovation photos will provide 
                                you with the inspiration you need to turn your house into the home of your dreams. <span className="display-none">From modern and 
                                sleek updates to cozy and warm transformations, we've got you covered. Start planning your next 
                                renovation project today and transform your space into a beautiful, functional, and personalized haven.</span>                                 
                            </p>
                            <p>
                                Visit our inspiration photos 
                                    <Link
                                        to="/inspiration-photos" 
                                        className='button' 
                                        onClick={()=> Event(`${window.location.pathname} page`, `Inspiration Ideas button was clicked from ${window.location.pathname} page Design section`, `${window.location.pathname} page Design section Inspiration Ideas button`)}
                                    > 
                                        <span className="contact-us">here </span> 
                                    </Link>  
                                to see the possibilities! 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}