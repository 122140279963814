import React from 'react';

const NewKitchenSvg = ({
     style = {},
     fill = "#000",
     width = "100%",
     className = "",
     viewBox = "0 0 64 64"
}) => (
    <svg 
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        className={`svg-icon ${className || ""}`}
        id="Layer_2_copy_2" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name="Layer 2 copy 2"
    >
        <path fill={fill} d="m15 31h-15v31h4v2h2v-2h24.876v2h2v-2h26.25v2h2v-2h2.874v-31zm0 29h-13v-20h13zm0-22h-13v-5h13zm32 22h-30v-27h30zm15 0h-13v-20h13zm0-22h-13v-5h13z"/>
        <path fill={fill} d="m11 44h2v5h-2z"/>
        <path fill={fill} d="m51 44h2v5h-2z"/>
        <path fill={fill} d="m44 40h-24v17h24zm-2 15h-20v-13h20z"/>
        <path fill={fill} d="m20 35h24v2h-24z"/>
        <path fill={fill} d="m22 0h-22v17h22zm-12 15h-8v-13h8zm10 0h-8v-13h8z"/>
        <path fill={fill} d="m42 17h22v-17h-22zm12-15h8v13h-8zm-10 0h8v13h-8z"/>
        <path fill={fill} d="m25 0v15.667l-2.5 3.333h-4.5v2h28v-2h-4.5l-2.5-3.333v-15.667zm2 2h10v13h-10zm-2 17 1.5-2h11l1.5 2z"/>
        <path fill={fill} d="m6 5h2v5h-2z"/>
        <path fill={fill} d="m16 5h2v5h-2z"/>
        <path fill={fill} d="m47 5h2v5h-2z"/>
        <path fill={fill} d="m57 5h2v5h-2z"/>
        <path fill={fill} d="m27 44h11v2h-11z"/>
    </svg>
)

export default NewKitchenSvg;