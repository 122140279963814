import React, {Component} from 'react';
import '../styles/Testimonials.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default class Testimonials extends Component {
    render() {
        var settings = {
            autoplay: true,
            dots: true,
            infinite: true,
            autoplaySpeed: 10000,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (  
            <div id="testimonials" className="testimonials-container">
                <h2>Testimonials</h2>
                <Slider {...settings}>
                    <div className="each-review">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <p><i className="fas fa-quote-left quote"></i>Great experience with Benwill Cabinets! Would recommend them to anyone looking to 
                            reface their kitchen cabinets - the quality of their work is amazing. Great customer service and friendly staff. The project manager 
                            was very helpful, organized and had great communication skills throughout the renovation.
                        </p><br></br>
                        <p>-Frank Zelano, Ontario</p>
                    </div>

                    <div className="each-review">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <p><i className="fas fa-quote-left quote"></i>We would definitely recommend Benwill Cabinets. The quality of the 
                            finished product is outstanding and we are very pleased with the result.
                        </p><br></br>
                        <p>-Christopher Melan, Ontario</p>
                    </div>

                    <div className="each-review">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <p><i className="fas fa-quote-left quote"></i>Overall great experience. That was a pleasure to deal with Ben and Will and their team. 
                            They were very polite, patient, helpful and what is the most important very professional. 
                        </p><br></br>
                        <p>-Carl Hopkins, Ontario</p>
                    </div>

                    <div className="each-review">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <p><i className="fas fa-quote-left quote"></i>We are very happy with the new kitchen renovation. From design to build, 
                            the quality and speed of the work is one of the best on the market. Benwill answered all of our questions and walked us through the entire process, 
                            it was quite easy to work with them. I highly recommend using them.
                        </p><br></br>
                        <p>-Joseph Brown, Ontario</p>
                    </div>

                    <div className="each-review">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <p><i className="fas fa-quote-left quote"></i>Great experience, Would recommend them to anyone looking to remodel their kitchen. 
                            William was very helpful, he was very responsive to all of our needs and kept us updated throughout the entire project! 
                            Great job!
                        </p><br></br>
                        <p>-Stanley Rickman, Ontario</p>
                    </div>

                    <div className="each-review">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <p><i className="fas fa-quote-left quote"></i>We were very pleased with the work done and the professionalism of all of the workers. 
                            They were very knowledgeable and pleasant to deal with.
                        </p><br></br>
                        <p>-Pat Yolle, Ontario</p>
                    </div>
                    
                    <div className="each-review">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <p><i className="fas fa-quote-left quote"></i>I highly recommend Benwill Cabinets. I got estimates from about a dozen of other 
                            companies, all of which were considerably more expensive. I decided to go with Benwill because of their fair price and the 
                            quality (I did eliminate a few other suspiciously cheap places where the wood was cracking on display). Very happy with my new kitchen!
                        </p><br></br>
                        <p>-Make Safero, Ontario</p>
                    </div>

                    <div className="each-review">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <p><i className="fas fa-quote-left quote"></i>Highly satisfied with the job you have done for me. Professional, dependable and reasonably priced for our renovation.  
                            Very pleased with the result.
                        </p><br></br>
                        <p>-Andrew Pearl, Ontario</p>
                    </div>
                </Slider>
            </div>
        )
    }
}

