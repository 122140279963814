import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import '../styles/CostEstimator.scss';
import BathroomSvg from "../utils/BathroomSvg";
import KitchenSvg from "../utils/KitchenSvg";
import PaintSvg from "../utils/PaintSvg";
import FloorSvg from "../utils/FloorSvg";
import BasementSvg from "../utils/BasementSvg";
import NewKitchenSvg from "../utils/NewKitchenSvg";
import KitchenRefacingSvg from "../utils/KitchenRefacingSvg";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ContactForm from "../components/ContactForm.jsx";

import { Event } from '../utils/tracking.js';

var Recaptcha = require('react-recaptcha');
let recaptchaInstance;
let recaptchaResponse = "";

export default class CostEstimator extends Component {   
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            description: '', 
            name: '', 
            email: '', 
            location: '',
            phoneNumber: '',
            validated: false,
            showForm: true,
            showSuccess: false,
            showFinalSuccess: false,
            userFilledTheForm: false,
            selected: false,
            kitchenSelected: false,
            newKitchenSelected: false,
            kitchenRefacingSelected: false, 
            cabinetSelected: false,
            buttonSubmitClicked: false,
            isVerified: false,
            isRecaptchaExpired: false,
            // costs: {
            //     kitchen: 990,
            //     floor: 129,
            // },
            // userSelections: {
            // }
            demolition: false,
            kitchenSize: '',
            countertopType: '',
            newFaucet: false,
            newSink: false,
            stoveRelocation: false,
            fridgeRelocation: false,
            kitchenType: '',
            kitchenRefacingDoorColour: false,
            kitchenRefacingNumberOfDoors: '',
            kitchenRefacingTypeOfProperty: '',
            bathroomSelected: false,
            bathroomSize: '',
            bathroomNewTub: false,
            bathroomNewVanity: false,
            bathroomNewFloorTiles: false,
            bathroomNewTubSurroundTiles: false,
            bathroomNewAccessories: false,
            bathroomPlumbingRelocation: false,
            bathroomElectricalRelocation: false,
            bathroomPainting: false,
            bathroomTypeOfProperty: "",
            bathroomTypeOfMaterials: '',
            basementSelected: false,
            basementSize: '',
            basementPermitProject: false,
            basementRoomsNumber: '',
            basementNewKitchen: false,
            basementNewWashroom: false,
            basementTypeOfMaterials: '',
            paintingSelected: false,
            paintingNumberOfRooms: '',
            paintingTotalArea: '',
            paintingPrepWork: false,
            paintingNumberOfDifferentColours: '',
            flooringSelected: false,
            flooringSize: '',
            flooringType: '',
            flooringBasement: false,
            flooringTypeOfMaterials: '',
            summary: 0,
            userAgreeToBeContacted: false,
            preContactFormFilled: false
        }
    } 

    getUserInfo = () => {
        const url = `https://api.geoapify.com/v1/ipinfo?&apiKey=87664a8ac8e04022963b693e7279f7b6`;
        fetch(url)
        .then(res => res.json())
        .then((response) => {
            //console.log('All possible user data: ', response)
            this.setState({
                allUserData: response,
                userIP: response.ip,
                userCountry: response.country.name,
                userProvince: response.state.name,
                userCity: response.city.name,
                userPostcode: response.postcode
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    componentDidMount = () => {
        this.getUserInfo();
    }

    recaptchaLoaded = () => {
        console.log('captcha successfully loaded');
    };
        
    verifyCallback = (response) => {
        recaptchaResponse = response;
        if (response) {
            this.setState({
                isVerified: true,
                isRecaptchaExpired: false
            })
        }
    };
    
    expiredCallback = () => {
        this.setState({ isRecaptchaExpired: true })
        alert("Your recatpcha has expired, please verify again ...");
        recaptchaInstance.reset();
    }

    moneyFormat = (price) => {
        const pieces = parseFloat(price).toFixed(2).split('')
        let ii = pieces.length - 3
        while ((ii-=3) > 0) {
            pieces.splice(ii, 0, ',')
        }
        return pieces.join('')
    }

    handleSubmit = e => { 
        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
            this.setState({ validated: true });
        } else {
            this.setState({ showForm: false, showSuccess: true});

            if (!localStorage.getItem('user')) {
                localStorage.setItem('user', this.state.userIP);           
                localStorage.setItem('userVisits', 1);
            } else if (!!localStorage.getItem('user') && localStorage.getItem('user') === this.state.userIP) {
                let numberOfVisits = localStorage.getItem('userVisits')
                localStorage.setItem('userVisits', Number(numberOfVisits) + 1);
            }
            
            const templateId = 'estimator';
            if (this.state.newKitchenSelected) {
                this.sendFeedback(templateId, {
                    userIP: this.state.allUserData.ip,
                    userCountry: this.state.userCountry,
                    userProvince: this.state.userProvince,
                    userCity: this.state.userCity,
                    userPostcode: this.state.userPostcode,
                    newKitchenSelected: this.state.newKitchenSelected ? 'yes' : 'no',
                    newKitchenDemolition: this.state.demolition ? 'yes' : 'no',
                    kitchenSize: this.state.kitchenSize ? this.state.kitchenSize : '-',
                    countertopType: this.state.countertopType ? this.state.countertopType : '-',
                    newFaucet: this.state.newFaucet ? 'yes' : 'no',
                    newSink: this.state.newSink ? 'yes' : 'no',
                    stoveRelocation: this.state.stoveRelocation ? 'yes' : 'no',
                    fridgeRelocation: this.state.fridgeRelocation ? 'yes' : 'no',
                    kitchenType: this.state.kitchenType ? this.state.kitchenType : '-',
                    newKitchenSummary: (this.state.newKitchenSelected && this.state.summary) ? `$${this.moneyFormat(this.state.summary)} - $${this.moneyFormat(this.state.summary*1.5)}` : '-',
                })
                .then(() => {
                    form.reset();  
                })
            } else if (this.state.kitchenRefacingSelected) {
                this.sendFeedback(templateId, {
                    userIP: this.state.allUserData.ip,
                    userCountry: this.state.userCountry,
                    userProvince: this.state.userProvince,
                    userCity: this.state.userCity,
                    userPostcode: this.state.userPostcode,
                    kitchenRefacingSelected: this.state.kitchenRefacingSelected ? 'yes' : 'no',
                    kitchenRefacingDoorColour: this.state.kitchenRefacingDoorColour ? 'yes' : 'no',
                    kitchenRefacingNumberOfDoors: this.state.kitchenRefacingNumberOfDoors ? this.state.kitchenRefacingNumberOfDoors :'-',
                    kitchenRefacingTypeOfProperty: this.state.kitchenRefacingTypeOfProperty ? this.state.kitchenRefacingTypeOfProperty : '-',
                    kitchenRefacingSummary: (this.state.kitchenRefacingSelected && this.state.summary) ? `$${this.moneyFormat(this.state.summary)} - $${this.moneyFormat(this.state.summary*1.5)}` : '-',
                })
                .then(() => {
                    form.reset();  
                })
            }  else if (this.state.bathroomSelected) {
                this.sendFeedback(templateId, {
                    userIP: this.state.allUserData.ip,
                    userCountry: this.state.userCountry,
                    userProvince: this.state.userProvince,
                    userCity: this.state.userCity,
                    userPostcode: this.state.userPostcode,
                    bathroomSelected: this.state.bathroomSelected ? 'yes' : 'no',
                    bathroomDemolition: this.state.demolition ? 'yes' : 'no',
                    bathroomSize: this.state.bathroomSize ? this.state.bathroomSize : '-',
                    bathroomNewTub: this.state.bathroomNewTub ? 'yes' : 'no',
                    bathroomNewVanity: this.state.bathroomNewVanity ? 'yes' : 'no',
                    bathroomNewFloorTiles: this.state.bathroomNewFloorTiles ? 'yes' : 'no',
                    bathroomNewTubSurroundTiles: this.state.bathroomNewTubSurroundTiles ? 'yes' : 'no',
                    bathroomNewAccessories: this.state.bathroomNewAccessories ? 'yes' : 'no',
                    bathroomPlumbingRelocation: this.state.bathroomPlumbingRelocation ? 'yes' : 'no',
                    bathroomElectricalRelocation: this.state.bathroomElectricalRelocation ? 'yes' : 'no',
                    bathroomPainting: this.state.bathroomPainting ? 'yes' : 'no',
                    bathroomTypeOfProperty: this.state.bathroomTypeOfProperty ? this.state.bathroomTypeOfProperty : '-',
                    bathroomTypeOfMaterials: this.state.bathroomTypeOfMaterials ? this.state.bathroomTypeOfMaterials : '-',
                    bathroomSummary: (this.state.bathroomSelected && this.state.summary) ? `$${this.moneyFormat(this.state.summary)} - $${this.moneyFormat(this.state.summary*1.5)}` : '-',
                })
                .then(() => {
                    form.reset();  
                })
            }   else if (this.state.basementSelected) {
                this.sendFeedback(templateId, {
                    userIP: this.state.allUserData.ip,
                    userCountry: this.state.userCountry,
                    userProvince: this.state.userProvince,
                    userCity: this.state.userCity,
                    userPostcode: this.state.userPostcode,
                    basementSelected: this.state.basementSelected ? 'yes' : 'no',
                    basementDemolition: this.state.demolition ? 'yes' : 'no',
                    basementSize: this.state.basementSize ? this.state.basementSize : '-',
                    basementPermitProject: this.state.basementPermitProject ? 'yes' : 'no',
                    basementRoomsNumber: this.state.basementRoomsNumber ? this.state.basementRoomsNumber : '-',
                    basementNewKitchen: this.state.basementNewKitchen ? 'yes' : 'no',
                    basementNewWashroom: this.state.basementNewWashroom ? 'yes' : 'no',
                    basementTypeOfMaterials: this.state.basementTypeOfMaterials ? this.state.basementTypeOfMaterials : '-',
                    basementSummary: (this.state.basementSelected && this.state.summary) ? `Starting from: $${this.moneyFormat(this.state.summary)}` : '-',
                })
                .then(() => {
                    form.reset();  
                })
            }   else if (this.state.paintingSelected) {
                this.sendFeedback(templateId, {
                    userIP: this.state.allUserData.ip,
                    userCountry: this.state.userCountry,
                    userProvince: this.state.userProvince,
                    userCity: this.state.userCity,
                    userPostcode: this.state.userPostcode,
                    paintingSelected: this.state.paintingSelected ? 'yes' : 'no',
                    paintingNumberOfRooms: this.state.paintingNumberOfRooms ? this.state.paintingNumberOfRooms : '-',
                    paintingTotalArea: this.state.paintingTotalArea ? this.state.paintingTotalArea : '-',
                    paintingPrepWork: this.state.paintingPrepWork ? 'yes' : 'no',
                    paintingNumberOfDifferentColours: this.state.paintingNumberOfDifferentColours ? this.state.paintingNumberOfDifferentColours : '-',
                    paintingSummary: (this.state.paintingSelected && this.state.summary) ? `$${this.moneyFormat(this.state.summary)} - $${this.moneyFormat(this.state.summary*1.5)}` : '-',
                })
                .then(() => {
                    form.reset();  
                })
            }   else if (this.state.flooringSelected) {
                this.sendFeedback(templateId, {
                    userIP: this.state.allUserData.ip || '-',
                    userCountry: this.state.userCountry,
                    userProvince: this.state.userProvince,
                    userCity: this.state.userCity,
                    userPostcode: this.state.userPostcode,
                    flooringSelected: this.state.flooringSelected ? 'yes' : 'no',
                    flooringDemolition: this.state.demolition ? 'yes' : 'no',
                    flooringSize: this.state.flooringSize ? this.state.flooringSize : '-',
                    flooringType: this.state.flooringType ? this.state.flooringType : '-',
                    flooringBasement: this.state.flooringBasement ? 'yes' : 'no',
                    flooringTypeOfMaterials: this.state.flooringTypeOfMaterials ? this.state.flooringTypeOfMaterials : '-',
                    flooringSummary: (this.state.flooringSelected && this.state.summary) ? `$${this.moneyFormat(this.state.summary)} - $${this.moneyFormat(this.state.summary*1.5)}` : '-',
                })
                .then(() => {
                    form.reset();  
                })
            }               
        }  
    }

    sendFeedback = (templateId, variables) => {
        return window.emailjs.send (
            'benwillcabinets', templateId,
            variables
        )
    }

    calculateSummary = () => {
        let demolition = 0;
        let kitchenSize = 0;
        let countertopType; 
        let newFaucet = 0; 
        let newSink = 0; 
        let stoveRelocation = 0;
        let fridgeRelocation = 0;  
        let kitchenType; 

        let kitchenRefacingNumberOfDoors; 
        let kitchenRefacingTypeOfProperty;

        let bathroomSize;
        let bathroomNewTub = 0;
        let bathroomNewVanity = 0;
        let bathroomNewFloorTiles = 0;
        let bathroomNewTubSurroundTiles = 0;
        let bathroomNewAccessories = 0;
        let bathroomPlumbingRelocation = 0;
        let bathroomElectricalRelocation = 0;
        let bathroomPainting = 0;
        let bathroomTypeOfProperty;
        let bathroomTypeOfMaterials;

        let basementSize;
        let basementPermitProject = 0;
        let basementRoomsNumber;
        let basementNewKitchen = 0;
        let basementNewWashroom = 0;
        let basementTypeOfMaterials;

        let paintingNumberOfRooms;
        let paintingTotalArea;
        let paintingPrepWork = 0;
        let paintingNumberOfDifferentColours;

        let flooringSize;
        let flooringType;
        let flooringBasement = 0;
        let flooringTypeOfMaterials;

        if (this.state.newKitchenSelected) {           
            if (this.state.demolition) {
                demolition = 600; 
            }

            if (this.state.kitchenSize) {
                kitchenSize = this.state.kitchenSize * 30;
            }
            
            if (this.state.countertopType === 'Laminate') {
                countertopType = 1000;
            } else if (this.state.countertopType === 'Quartz') {
                countertopType = 3000;
            }                    
            
            if (this.state.newFaucet) {
                newFaucet = 350;
            }
            
            if (this.state.newSink) {
                newSink = 350;
            }
                
            if (this.state.stoveRelocation) {
                stoveRelocation = 350; 
            }                
            
            if (this.state.fridgeRelocation) {
                fridgeRelocation = 250;  
            }            
            
            if (this.state.kitchenType === 'Budget oriented') {
                kitchenType = (demolition + kitchenSize + countertopType + newFaucet + newSink + stoveRelocation + fridgeRelocation) * 0.8; 
            } else if (this.state.kitchenType === 'Mid-end') {
                kitchenType = (demolition + kitchenSize + countertopType + newFaucet + newSink + stoveRelocation + fridgeRelocation) * 1.38;
            } else if (this.state.kitchenType === 'High-end') {
                kitchenType = (demolition + kitchenSize + countertopType + newFaucet + newSink + stoveRelocation + fridgeRelocation) * 1.85;
            } 

            this.setState({
                summary: kitchenType
            })
                
        } else if (this.state.kitchenRefacingSelected) {
            if (!!this.state.kitchenRefacingNumberOfDoors) {
                kitchenRefacingNumberOfDoors = this.state.kitchenRefacingNumberOfDoors * 150;
            }
            
            if (this.state.kitchenRefacingTypeOfProperty === "House") {
                kitchenRefacingTypeOfProperty = 0;
            } else if (this.state.kitchenRefacingTypeOfProperty === "Condo") {
                kitchenRefacingTypeOfProperty = 400;
            }

            if (!!this.state.kitchenRefacingDoorColour) {
                this.setState({
                    summary: (kitchenRefacingNumberOfDoors + kitchenRefacingTypeOfProperty) * 1.5 
                })               
            } else if (!this.state.kitchenRefacingDoorColour){
                this.setState({
                    summary: (kitchenRefacingNumberOfDoors + kitchenRefacingTypeOfProperty)
                })   
            }   

        } else if (this.state.bathroomSelected) {
            if (this.state.demolition) {
                demolition = 1700; 
            }

            if (this.state.bathroomSize) {
                bathroomSize = this.state.bathroomSize * 50;
            }

            if (this.state.bathroomNewTub) {
                bathroomNewTub = 850;
            }            

            if (this.state.bathroomNewVanity) {
                bathroomNewVanity = 900;
            }
            
            if (this.state.bathroomNewFloorTiles) {
                bathroomNewFloorTiles = this.state.bathroomSize * 15;
            }

            if (this.state.bathroomNewTubSurroundTiles) {
                bathroomNewTubSurroundTiles = this.state.bathroomSize * 8;
            }
            
            if (this.state.bathroomNewAccessories) {
                bathroomNewAccessories = 200;
            }

            if (this.state.bathroomPlumbingRelocation) {
                bathroomPlumbingRelocation = 1000;
            }
            
            if (this.state.bathroomElectricalRelocation) {
                bathroomElectricalRelocation = 900;
            }
            
            if (this.state.bathroomPainting) {
                bathroomPainting = 1200;
            }

            if (this.state.bathroomTypeOfProperty === 'House') {
                bathroomTypeOfProperty = 0;
            } else if (this.state.bathroomTypeOfProperty === 'Condo') {
                bathroomTypeOfProperty = 600;
            }

            if (this.state.bathroomTypeOfMaterials === 'Budget oriented') {
                bathroomTypeOfMaterials = (demolition + bathroomSize + bathroomNewTub +  bathroomNewVanity + bathroomNewFloorTiles + bathroomNewTubSurroundTiles + bathroomNewAccessories + bathroomPlumbingRelocation + bathroomElectricalRelocation + bathroomPainting + bathroomTypeOfProperty) * 0.75;
            } else if (this.state.bathroomTypeOfMaterials === 'Mid-end') {
                bathroomTypeOfMaterials = (demolition + bathroomSize + bathroomNewTub +  bathroomNewVanity + bathroomNewFloorTiles + bathroomNewTubSurroundTiles + bathroomNewAccessories + bathroomPlumbingRelocation + bathroomElectricalRelocation + bathroomPainting + bathroomTypeOfProperty) * 1.1;
            } else if (this.state.bathroomTypeOfMaterials === 'High-end') {
                bathroomTypeOfMaterials = (demolition + bathroomSize + bathroomNewTub +  bathroomNewVanity + bathroomNewFloorTiles + bathroomNewTubSurroundTiles + bathroomNewAccessories + bathroomPlumbingRelocation + bathroomElectricalRelocation + bathroomPainting + bathroomTypeOfProperty) * 1.65;
            } 

            this.setState({
                summary: bathroomTypeOfMaterials
            })

        } else if (this.state.basementSelected) {
            if (this.state.demolition) {
                demolition = 2400; 
            }

            if (this.state.basementSize) {
                basementSize = this.state.basementSize * 45;
            }

            if (this.state.basementPermitProject) {
                basementPermitProject = 4000;
            }
            
            if (this.state.basementRoomsNumber) {
                basementRoomsNumber = this.state.basementRoomsNumber * 3500;
            }
            
            if (this.state.basementNewKitchen) {
                basementNewKitchen = 8000;
            }

            if (this.state.basementNewWashroom) {
                basementNewWashroom = 10000;
            }
            
            if (this.state.basementTypeOfMaterials === 'Budget oriented') {
                basementTypeOfMaterials = (demolition + basementSize + basementPermitProject + basementRoomsNumber + basementNewKitchen + basementNewWashroom) * 0.6;
            } else if (this.state.basementTypeOfMaterials === 'Mid-end') {
                basementTypeOfMaterials = (demolition + basementSize + basementPermitProject + basementRoomsNumber + basementNewKitchen + basementNewWashroom);
            } else if (this.state.basementTypeOfMaterials === 'High-end') {
                basementTypeOfMaterials = (demolition + basementSize + basementPermitProject + basementRoomsNumber + basementNewKitchen + basementNewWashroom) * 1.1;
            } 
            
            this.setState({
                summary:basementTypeOfMaterials  
            })
           
        } else if (this.state.paintingSelected) {
            if (this.state.paintingNumberOfRooms) {
                paintingNumberOfRooms = this.state.paintingNumberOfRooms * 300;
            }         

            if (this.state.paintingTotalArea) {
                paintingTotalArea = this.state.paintingTotalArea * 5;
            } 
            
            if (this.state.paintingPrepWork) {
                paintingPrepWork = 1500;
            }
            
            if (this.state.paintingNumberOfDifferentColours <= 2) {
                paintingNumberOfDifferentColours = 500;
            } else if (this.state.paintingNumberOfDifferentColours >= 3) {
                paintingNumberOfDifferentColours = 1300;
            }
            
            this.setState({
                summary: paintingNumberOfRooms + paintingTotalArea + paintingPrepWork + paintingNumberOfDifferentColours 
            })

        } else if (this.state.flooringSelected) {
            if (this.state.demolition) {
                demolition = 1000; 
            }

            if (this.state.flooringSize) {
                flooringSize = this.state.flooringSize;
            }

            if (this.state.flooringType === 'Laminate') {
                flooringType = flooringSize * 4;
            } else if (this.state.flooringType === 'Vinyl plank') {
                flooringType = flooringSize * 6;
            } else if (this.state.flooringType === 'Tiles') {
                flooringType = flooringSize * 10;
            } else if (this.state.flooringType === 'Hard wood') {
                flooringType = flooringSize * 13;
            }

            if (this.state.flooringBasement) {
                flooringBasement = flooringSize * 4;
            }
            
            if (this.state.flooringTypeOfMaterials === 'Budget oriented') {
                flooringTypeOfMaterials = (demolition + flooringType + flooringBasement) * 0.75;
            } else if (this.state.flooringTypeOfMaterials === 'Mid-end') {
                flooringTypeOfMaterials = (demolition + flooringType + flooringBasement) * 0.9;
            } else if (this.state.flooringTypeOfMaterials === 'High-end') {
                flooringTypeOfMaterials = (demolition + flooringType + flooringBasement);
            } 
            
            this.setState({
                summary: flooringTypeOfMaterials
            })      
        }
    }

    handleSubmitPreContactForm = e => { 
        e.preventDefault()
        const form = e.currentTarget;
    
        if (form.checkValidity() === false) {
            e.stopPropagation();
            this.setState({validated: true});
        } else if (this.state.isVerified === true && this.state.isRecaptchaExpired === false) {
            this.setState({
                showForm: false, 
                showSuccess: true, 
                userFilledTheForm: true,
                preContactFormFilled: true
            });
            
            const captcha = recaptchaResponse;
            fetch('/api/recaptcha', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ captcha }),
            })
            .then(res => res.json())
            .then(data => {
                console.log(data);
            });
            //recaptchaInstance.reset();

            const templateId = 'to_owner';
            this.sendFeedbackPreContactForm(templateId, {
                userIP: this.state.allUserData.ip || '-', 
                description: this.state.description || '-', 
                name: this.state.name || '-', 
                email: this.state.email || '-', 
                location: this.state.location || '-', 
                phoneNumber: this.state.phoneNumber || '-'
            })
            .then(() => {
                this.setState({ 
                    userIP: '', 
                    description: '', 
                    name: '', 
                    email: '', 
                    location: '', 
                    phoneNumber: '', 
                    showForm: false, 
                    showSuccess: true,
                    preContactFormFilled: true                   
                });
            })
        } else {
            alert("Please verify that you are a human!");
        }
    }
        
    sendFeedbackPreContactForm = (templateId, variables) => {
        return window.emailjs.send (
            'benwillcabinets', templateId,
            variables
        )
    }

    handleSubmitUserAgreeToBeContactedForm = e => { 
        e.preventDefault()
        
        this.setState({
            showFinalSuccess: true,
            showSuccess: true,
            userAgreeToBeContacted: true
        });

        const templateId = 'estimator';
        this.sendFeedbackUserAgreeToBeContactedForm(templateId, {
            userIP: this.state.allUserData.ip, 
            userAgreeToBeContacted: "yes",
            userCountry: this.state.userCountry,
            userProvince: this.state.userProvince,
            userCity: this.state.userCity,
            userPostcode: this.state.userPostcode,
        })
        .then(() => {
            this.setState({ 
                userIP: '', 
                showFinalSuccess: true,
                showSuccess: true   
            });
        })
    }

    sendFeedbackUserAgreeToBeContactedForm = (templateId, variables) => {
        return window.emailjs.send (
            'benwillcabinets', templateId,
            variables
        )
    }
    render() {         
        return (
            <div className="cost-estimator-container">
                <Helmet>
                    <meta name="description" content="" />
                    <meta name="keywords" content="bathroom renovations, kitchen renovations, basement renovations, kitchen remodeling, renovation contractor, bathroom remodelling, kitchen reno, washroom renovations, home renovation contractors, small bathroom renovations, basement contractors, kitchen renovation near me, bathroom contractors near me, home remodeling contractors, home construction companies near me, home renovations near me, kitchen and bathroom renovations, residential contractor, kitchen renovation contractors near me, bedroom renovation, bathroom renovation contractors, kitchen cabinet renovation, basement remodels, small bathroom reno, budget bathroom renovation, kitchen renovation contractor, complete bathroom renovation, kitchen renovation company, basement renovation companies, kitchen renovation companies near me, kitchen construction company, basement bathroom contractors, basement bathroom renovation, bathroom renovations near me, renovation contractors near me, best kitchen remodelers near me, home renovation companies near me, kitchen bathroom renovations, house renovation contractor, bathroom contractors, kitchen contractors, cost estimator, Kitchen Online Estimator, bathroom Online Estimator, basement Online Estimator, renovation estimator, calculate your budget, online estimator." />
                    <title>Online Estimator | Benwill Cabinets</title>
                    <link rel="canonical" href="https://www.benwillcabinets.com/cost-estimator" />                    
                    <meta property="og:title" content="Online Estimator | Benwill Cabinets" />
                    <meta property="og:description" content="" />
                    <meta property="og:url" content="https://www.benwillcabinets.com/cost-estimator" />
                    <meta name="twitter:description" content="" />
                    <meta name="twitter:title" content="Online Estimator| Benwill Cabinets" />
                </Helmet>
                <div id="breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Online Estimator</Breadcrumb.Item>                        
                    </Breadcrumb>
                </div>
                {this.state.selected === true && !this.state.showSuccess &&
                    <div id='change-category-link-container'>
                        <Button
                            variant="link"
                            onClick={() => window.location.reload()}
                        >
                            Change a Category
                        </Button>
                    </div>
                }
                {this.state.selected === false &&
                <div>               
                    <h2>Your Renovation Project Starts Here</h2>
                    <h6>- Choose a Category -</h6>
                    <div className="svg-container">
                        <div    
                            id="kitchen" 
                            onClick={() => this.setState({selected: true, kitchenSelected: true})}
                        >
                            <KitchenSvg 
                                fill="#fff"
                                width={150}
                                className="kitchen"
                            />
                            <p>Kitchen</p>
                        </div>
                        <div 
                            id="bathroom"
                            onClick={() => this.setState({selected: true, bathroomSelected: true})}
                        >
                            <BathroomSvg 
                                fill="#fff"
                                width={150}
                                className="bathroom"
                            />
                            <p>Bathroom</p>
                        </div>
                        <div 
                            id="basement"
                            onClick={() => this.setState({selected: true, basementSelected: true})}
                        >
                            <BasementSvg 
                                fill="#fff"
                                width={150}
                                className="basement"
                            />  
                            <p>Basement</p>
                        </div> 
                        <div 
                            id="paint"
                            onClick={() => this.setState({selected: true, paintingSelected: true})}
                        >
                            <PaintSvg 
                                fill="#fff"
                                width={150}
                                className="paint"
                            />
                            <p>Painting</p>
                        </div>
                        <div 
                            id="floor"
                            onClick={() => this.setState({selected: true, flooringSelected: true})}
                        >
                            <FloorSvg 
                                fill="#fff"
                                width={150}
                                className="floor"
                            />  
                            <p>Flooring</p>
                        </div>                          
                    </div>
                </div>
                }

                {this.state.kitchenSelected === true &&
                    <div>
                        <h2>What type of renovation are you planning?</h2>
                        <div className="svg-container">
                            <div 
                                id="new-kitchen" 
                                onClick={() => this.setState({newKitchenSelected: true, kitchenSelected: false})}
                            >
                                <NewKitchenSvg 
                                    fill="#fff"
                                    width={150}
                                    className="new-kitchen"
                                />
                                <p>Brand New Kitchen</p>
                            </div>
                            <div 
                                id="kitchen-refacing" 
                                onClick={() => this.setState({kitchenRefacingSelected: true, kitchenSelected: false})}
                            >
                                <KitchenRefacingSvg 
                                    fill="#fff"
                                    width={150}
                                    className="kitchen-refacing"
                                />
                                <p>Kitchen Refacing</p>
                                <p className='smaller-text'>(cabinet doors/side panels</p>
                                <p className='smaller-text'>replacement only)</p>
                            </div>
                        </div>
                    </div>
                }

                {this.state.newKitchenSelected === true && this.state.showForm &&
                    <div>                                                
                        <div>
                            <h2>What do you want to change in your kitchen?</h2>
                            <p className='note'>
                                *Please note rough estimate includes new kitchen cabinets, countertop, basic plumbing, electrical and any selected type of 
                                work listed on this page. <br/>Flooring, plastering, or walls modification services are not included in this estimate but can 
                                be added separately.
                            </p>
                            <Form
                                noValidate 
                                validated={this.state.validated} 
                                onSubmit={this.handleSubmit}
                            >   
                                <div className='input-container'>   
                                    <div className='column1'>
                                        <Form.Group controlId="validationCustom01">
                                            <Form.Label>Sq ft of the kitchen,<br/>entire room (120 minimum):</Form.Label>
                                            <Form.Control 
                                                type="number"
                                                required
                                                min="120"
                                                name='kitchen size'
                                                value={this.state.kitchenSize}
                                                onChange={(e) => this.setState({ kitchenSize: e.target.value })}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please input minimum sq ft of the kitchen
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                        </Form.Group>  
                                        <Form.Group controlId="validationCustom02">
                                            <Form.Label>Countertop type:</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                required
                                                onChange={(e) => this.setState({ countertopType: e.target.value })}
                                            >
                                                <option value="">Make a selection</option>
                                                <option key='Laminate' value='Laminate'>Laminate</option>
                                                <option key='Quartz' value='Quartz'>Quartz</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please select countertop type
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                        </Form.Group> 
                                        <Form.Group controlId="validationCustom03">
                                            <Form.Label>Kitchen type:</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                required
                                                onChange={(e) => this.setState({ kitchenType: e.target.value })}
                                            >
                                                <option value="">Make a selection</option>
                                                <option key='Budget oriented' value='Budget oriented'>Budget oriented</option>
                                                <option key='Mid-end' value='Mid-end'>Mid-end</option>
                                                <option key='High-end' value='High-end'>High-end</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please select kitchen type
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                        </Form.Group> 
                                    </div>
                                    <div className='column2'>
                                        <Form.Group>                                  
                                            <Form.Check 
                                                type='checkbox' 
                                                label="I need demolition"
                                                name="demolition"                                        
                                                checked={this.state.demolition}                                        
                                                onChange={(e) => this.setState({ demolition: e.target.checked })}
                                            />  
                                        </Form.Group> 
                                        <div className="text">I would like to have in my kitchen:</div>
                                        <Form.Group className="mb-3">                                  
                                            <Form.Check 
                                                type='checkbox' 
                                                label="New sink"
                                                checked={this.state.countertopType === 'Quartz' ? 'yes' : this.state.newSink}
                                                onChange={(e) => this.state.countertopType !== 'Quartz' ? this.setState({ newSink: e.target.checked }) : this.setState({ newSink: 'yes' })}
                                                id={this.state.countertopType === 'Quartz' ? "disabledFieldsetCheck" : ''}
                                            />  
                                            <Form.Check 
                                                type='checkbox' 
                                                label="New faucet"
                                                checked={this.state.newFaucet}
                                                onChange={(e) => this.setState({ newFaucet: e.target.checked })}
                                            />  
                                        </Form.Group> 
                                        <div  className='text'>I would like to:</div>        
                                        <Form.Group>                                  
                                            <Form.Check 
                                                type='checkbox' 
                                                label="Relocate the stove"
                                                checked={this.state.stoveRelocation}
                                                onChange={(e) => this.setState({ stoveRelocation: e.target.checked })}
                                            />  
                                            <Form.Check 
                                                type='checkbox' 
                                                label="Relocate the fridge"
                                                checked={this.state.fridgeRelocation}
                                                onChange={(e) => this.setState({ fridgeRelocation: e.target.checked })}
                                            />  
                                        </Form.Group>
                                    </div>
                                </div>                     
                                <Button
                                    className='show-my-estimate-button'
                                    type="submit"
                                    onClick={this.calculateSummary}
                                >
                                    Show My Estimate
                                </Button>      
                            </Form>
                        </div>                        
                    </div>
                }

                {this.state.kitchenRefacingSelected === true && this.state.showForm &&
                    <div>
                        <h2>What do you want to change in your kitchen?</h2>
                        <Form
                            noValidate 
                            validated={this.state.validated} 
                            onSubmit={this.handleSubmit}
                        >   
                            <div className='input-container'>   
                                <div className='column1'>
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>Number of doors (12 minimum):</Form.Label>
                                        <Form.Control 
                                            type="number"
                                            min='12'
                                            required
                                            name='number of doors'
                                            value={this.state.kitchenRefacingNumberOfDoors}
                                            onChange={(e) => this.setState({ kitchenRefacingNumberOfDoors: e.target.value })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please input number of doors
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="validationCustom02">
                                        <Form.Label>Type of property:</Form.Label>
                                        <Form.Control 
                                            as="select"
                                            required
                                            onChange={(e) => this.setState({ kitchenRefacingTypeOfProperty: e.target.value })}
                                        >
                                            <option value="">Make a selection</option>
                                            <option key='House' value='House'>House</option>
                                            <option key='Condo' value='Condo'>Condo</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select the type of property
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group> 
                                </div>
                                <div className='column2'>
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="New doors color will be different from the existing"
                                            name="door colour"
                                            checked={this.state.kitchenRefacingDoorColour}
                                            onChange={(e) => this.setState({ kitchenRefacingDoorColour: e.target.checked })}
                                        />  
                                    </Form.Group>
                                </div>
                            </div>                                             
                            <Button
                                className='show-my-estimate-button'
                                type="submit"
                                onClick={this.calculateSummary}
                            >
                                Show My Estimate
                            </Button>      
                        </Form>
                    </div>
                }

                {this.state.bathroomSelected === true && this.state.showForm &&
                    <div>
                        <h2>What do you want to change in your bathroom?</h2>
                        <Form
                            noValidate 
                            validated={this.state.validated} 
                            onSubmit={this.handleSubmit}
                        >              
                            <div className='input-container'>   
                                <div className='column1'>
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>Sq ft of the bathroom:</Form.Label>
                                        <Form.Control 
                                            type="number"
                                            required
                                            min="60"
                                            name='bathroomSize size'
                                            value={this.state.bathroomSize}
                                            onChange={(e) => this.setState({ bathroomSize: e.target.value })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please input sq ft of the bathroom
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>   
                                    <Form.Group controlId="validationCustom02">
                                        <Form.Label>Type of property:</Form.Label>
                                        <Form.Control 
                                            as="select"
                                            required
                                            onChange={(e) => this.setState({ bathroomTypeOfProperty: e.target.value })}
                                        >
                                            <option value="">Make a selection</option>
                                            <option key='House' value='House'>House</option>
                                            <option key='Condo' value='Condo'>Condo</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select the type of property
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="validationCustom03">
                                        <Form.Label>Type of materials:</Form.Label>
                                        <Form.Control 
                                            as="select"
                                            required
                                            onChange={(e) => this.setState({ bathroomTypeOfMaterials: e.target.value })}
                                        >
                                            <option value="">Make a selection</option>
                                            <option key='Budget oriented' value='Budget oriented'>Budget oriented</option>
                                            <option key='Mid-end' value='Mid-end'>Mid-end</option>
                                            <option key='High-end' value='High-end'>High-end</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select type of materials
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>
                                </div>  
                                <div className='column2'> 
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="I need demolition"
                                            name="demolition"
                                            checked={this.state.demolition}                                        
                                            onChange={(e) => this.setState({ demolition: e.target.checked })}
                                        />  
                                    </Form.Group>  
                                    <div className='text'>I would like to have in my bathroom:</div>
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="New tub"
                                            name="bathroom new tub"
                                            checked={this.state.bathroomNewTub}
                                            onChange={(e) => this.setState({ bathroomNewTub: e.target.checked })}
                                        />  
                                    </Form.Group> 
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="New vanity"
                                            name="bathroom new vanity"
                                            checked={this.state.bathroomNewVanity}
                                            onChange={(e) => this.setState({ bathroomNewVanity: e.target.checked })}
                                        />  
                                    </Form.Group>  
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="New floor tiles"
                                            name="bathroom new floor tiles"
                                            checked={this.state.bathroomNewFloorTiles}
                                            onChange={(e) => this.setState({ bathroomNewFloorTiles: e.target.checked })}
                                        />  
                                    </Form.Group>    
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="New tub surround tiles"
                                            name="bathroom new tub surround tiles"
                                            checked={this.state.bathroomNewTubSurroundTiles}
                                            onChange={(e) => this.setState({ bathroomNewTubSurroundTiles: e.target.checked })}
                                        />  
                                    </Form.Group>    
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="New bathroom accessories"
                                            name="bathroom new accessories"
                                            checked={this.state.bathroomNewAccessories}
                                            onChange={(e) => this.setState({ bathroomNewAccessories: e.target.checked })}
                                        />  
                                    </Form.Group>                              
                                    <div  className='text'>I would like to:</div> 
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="Relocate plumbing"
                                            name="bathroom plumbing relocation"
                                            checked={this.state.bathroomPlumbingRelocation}
                                            onChange={(e) => this.setState({ bathroomPlumbingRelocation: e.target.checked })}
                                        />  
                                    </Form.Group>     
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="Relocate electrical"
                                            name="bathroom electrical relocation"
                                            checked={this.state.bathroomElectricalRelocation}
                                            onChange={(e) => this.setState({ bathroomElectricalRelocation: e.target.checked })}
                                        />  
                                    </Form.Group>  
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="Repaint the room"
                                            name="bathroom painting"
                                            checked={this.state.bathroomPainting}
                                            onChange={(e) => this.setState({ bathroomPainting: e.target.checked })}
                                        />  
                                    </Form.Group> 
                                </div>                                 
                            </div>                           
                            <Button
                                className='show-my-estimate-button'
                                type="submit"
                                onClick={this.calculateSummary}
                            >
                                Show My Estimate
                            </Button>      
                        </Form>
                    </div>
                }

                {this.state.basementSelected === true && this.state.showForm &&
                    <div>                     
                        <h2>What do you want to change in your basement?</h2>
                        <Form
                            noValidate 
                            validated={this.state.validated} 
                            onSubmit={this.handleSubmit}
                        >     
                            <div className='input-container'>   
                                <div className='column1'>
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>Sq ft of the basement:</Form.Label>
                                        <Form.Control 
                                            type="number"
                                            required
                                            min="400"
                                            name='basement size'
                                            value={this.state.basementSize}
                                            onChange={(e) => this.setState({ basementSize: e.target.value })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please input sq ft of the basement
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group> 
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>Number of basement rooms:</Form.Label>
                                        <Form.Control 
                                            type="number"
                                            required
                                            min="1"
                                            name='basement rooms number'
                                            value={this.state.basementRoomsNumber}
                                            onChange={(e) => this.setState({ basementRoomsNumber: e.target.value })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please input number of basement doors
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="validationCustom03">
                                        <Form.Label>Type of materials:</Form.Label>
                                        <Form.Control 
                                            as="select"
                                            required
                                            onChange={(e) => this.setState({ basementTypeOfMaterials: e.target.value })}
                                        >
                                            <option value="">Make a selection</option>
                                            <option key='Budget oriented' value='Budget oriented'>Budget oriented</option>
                                            <option key='Mid-end' value='Mid-end'>Mid-end</option>
                                            <option key='High-end' value='High-end'>High-end</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select type of materials
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>   
                                </div>
                                <div className='column2'>
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="I need demolition"
                                            name="demolition"
                                            checked={this.state.demolition}                                        
                                            onChange={(e) => this.setState({ demolition: e.target.checked })}
                                        />  
                                    </Form.Group> 
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="It is a permit project"
                                            name="basement permit project"
                                            checked={this.state.basementPermitProject}
                                            onChange={(e) => this.setState({ basementPermitProject: e.target.checked })}
                                        />  
                                    </Form.Group> 
                                    <div className='text'>I would like to have in my basement:</div>
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="New kitchen"
                                            name="basement new kitchen"
                                            checked={this.state.basementNewKitchen}
                                            onChange={(e) => this.setState({ basementNewKitchen: e.target.checked })}
                                        />  
                                    </Form.Group>  
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="New washroom"
                                            name="basement new washroom"
                                            checked={this.state.basementNewWashroom}
                                            onChange={(e) => this.setState({ basementNewWashroom: e.target.checked })}
                                        />  
                                    </Form.Group>
                                </div>
                            </div> 
                            <Button
                                className='show-my-estimate-button'
                                type="submit"
                                onClick={this.calculateSummary}
                            >
                                Show My Estimate
                            </Button>      
                        </Form>
                    </div>
                }

                {this.state.paintingSelected === true && this.state.showForm &&
                    <div>                    
                        <h2>What type of work need to be done?</h2>
                        <Form
                            noValidate 
                            validated={this.state.validated} 
                            onSubmit={this.handleSubmit}
                        >      
                            <div className='input-container'>   
                                <div className='column1'>
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>Number of rooms to be painted:</Form.Label>
                                        <Form.Control 
                                            type="number"
                                            required
                                            min="1"
                                            name='painting number of rooms'
                                            value={this.state.paintingNumberOfRooms}
                                            onChange={(e) => this.setState({ paintingNumberOfRooms: e.target.value })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please input number of rooms
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>                       
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>Total sq ft of the painting area:</Form.Label>
                                        <Form.Control 
                                            type="number"
                                            required
                                            min="1"
                                            name='painting total area'
                                            value={this.state.paintingTotalArea}
                                            onChange={(e) => this.setState({ paintingTotalArea: e.target.value })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please input total sq ft of the painting area
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>Number of different colours:</Form.Label>
                                        <Form.Control 
                                            type="number"
                                            required
                                            min="1"
                                            name='painting number of different colours'
                                            value={this.state.paintingNumberOfDifferentColours}
                                            onChange={(e) => this.setState({ paintingNumberOfDifferentColours: e.target.value })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please input the number of different colours
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='column2'>
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="Does the preparation work required?"
                                            name="painting prep work"
                                            checked={this.state.paintingPrepWork}
                                            onChange={(e) => this.setState({ paintingPrepWork: e.target.checked })}
                                        />  
                                    </Form.Group> 

                                </div>
                            </div> 
                            <Button
                                type="submit"
                                onClick={this.calculateSummary}
                            >
                                Show My Estimate
                            </Button>      
                        </Form>
                    </div>
                }
                
                {this.state.flooringSelected === true && this.state.showForm &&
                    <div>                      
                        <h2>What type of work need to be done?</h2>
                        <Form
                            noValidate 
                            validated={this.state.validated} 
                            onSubmit={this.handleSubmit}
                        >           
                            <div className='input-container'>   
                                <div className='column1'>
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>Sq ft of the floor:</Form.Label>
                                        <Form.Control 
                                            type="number"
                                            required
                                            min="1"
                                            name='floor size'
                                            value={this.state.flooringSize}
                                            onChange={(e) => this.setState({ flooringSize: e.target.value })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please input sq ft of the floor
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>   
                                    <Form.Group controlId="validationCustom03">
                                        <Form.Label>Type of flooring:</Form.Label>
                                        <Form.Control 
                                            as="select"
                                            required
                                            onChange={(e) => this.setState({ flooringType: e.target.value })}
                                        >
                                            <option value="">Make a selection</option>
                                            <option key='Laminate' value='Laminate'>Laminate</option>
                                            <option key='Vinyl plank' value='Vinyl plank'>Vinyl plank</option>
                                            <option key='Tiles' value='Tiles'>Tiles</option>
                                            <option key='Hard wood' value='Hard wood'>Hard wood</option>                                    
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select type of flooring
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="validationCustom03">
                                        <Form.Label>Type of materials:</Form.Label>
                                        <Form.Control 
                                            as="select"
                                            required
                                            onChange={(e) => this.setState({ flooringTypeOfMaterials: e.target.value })}
                                        >
                                            <option value="">Make a selection</option>
                                            <option key='Budget oriented' value='Budget oriented'>Budget oriented</option>
                                            <option key='Mid-end' value='Mid-end'>Mid-end</option>
                                            <option key='High-end' value='High-end'>High-end</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select type of materials
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='column2'>
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="I need demolition"
                                            name="demolition"
                                            checked={this.state.demolition}                                        
                                            onChange={(e) => this.setState({ demolition: e.target.checked })}
                                        />  
                                    </Form.Group> 
                                    <Form.Group>                                  
                                        <Form.Check 
                                            type='checkbox' 
                                            label="Does subfloor required?"
                                            name="flooring basement"
                                            checked={this.state.flooringBasement}
                                            onChange={(e) => this.setState({ flooringBasement: e.target.checked })}
                                        />  
                                    </Form.Group>   
                                </div>
                            </div>                                                       
                            <Button
                                className='show-my-estimate-button'
                                type="submit"
                                onClick={this.calculateSummary}
                            >
                                Show My Estimate
                            </Button>      
                        </Form>
                    </div>
                }

                {!!this.state.showSuccess && !this.state.userFilledTheForm && Number(localStorage.getItem('userVisits')) > 3 &&
                <div className='pre-estimate-form'>
                    <h3>To get yor free estimate please provide following information:</h3>
                    <Form 
                        noValidate 
                        validated={this.state.validated} 
                        onSubmit={this.handleSubmitPreContactForm}
                    >
                        <Form.Group controlId="validationCustom01">
                            <Form.Control
                                required
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={this.state.name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please input your name.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom02">
                            <Form.Control
                                required
                                type="tel"
                                pattern="^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$"
                                placeholder="Phone number"
                                name="phoneNumber"
                                value={this.state.phoneNumber}
                                onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please input your phone number.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>                
                        <Form.Group controlId="validationCustom03">
                            <Form.Control
                                required
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please input your email.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom04">
                            <Form.Control
                                required
                                type="text"
                                placeholder="City"
                                name="location"
                                value={this.state.location}
                                onChange={(e) => this.setState({ location: e.target.value })}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please input your location.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control as="textarea" rows="3"
                                type="text"
                                placeholder="Description"
                                name="description"
                                value={this.state.description}
                                onChange={(e) => this.setState({ description: e.target.value })}
                            />
                        </Form.Group>               
                        <Recaptcha
                            ref={e => recaptchaInstance = e}
                            sitekey="6LfSpPIUAAAAAIT7RGSvkwXsawDVXsIZg2hQhlIK"
                            render="explicit"
                            verifyCallback={this.verifyCallback}
                            onloadCallback={this.recaptchaLoaded}
                            expiredCallback={this.expiredCallback}
                        />
                        <Button 
                            className="contact-button pre" 
                            type="submit" 
                            onClick={()=> Event("Show My Estimate from Cost estimator page", "Show My Estimate from Cost estimator page was clicked", "Cost estimator page Contact us")}
                        >
                            Show My Estimate
                        </Button>
                    </Form> 
                </div>
                }
                {!this.state.userAgreeToBeContacted && ((!!this.state.showSuccess && !this.state.showFinalSuccess && Number(localStorage.getItem('userVisits')) <= 3) || (!!this.state.showFinalSuccess && !!this.state.showSuccess && Number(localStorage.getItem('userVisits')) >= 3 && !!this.state.userFilledTheForm) || (!this.state.showFinalSuccess && !!this.state.showSuccess && Number(localStorage.getItem('userVisits')) >= 3 && !!this.state.userFilledTheForm && !!this.state.preContactFormFilled) )&&
                    <div>
                        <div id="breadcrumb">
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/cost-estimator">Cost Estimator</Breadcrumb.Item>
                                <Breadcrumb.Item active>Final Estimate</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        {!this.state.basementSelected &&
                        <>
                            <h4>Your Approximate Estimate is:</h4>                        
                            <h3>${this.moneyFormat(this.state.summary * 1.05)} - ${this.moneyFormat(this.state.summary * 1.55)}</h3>
                        </>
                        }
                        {!!this.state.basementSelected &&
                        <>
                            <h4>Your Approximate Estimate is Starting From:</h4> 
                            <h3>${this.moneyFormat(this.state.summary * 1.05)}</h3>
                        </>
                        }
                        <OverlayTrigger
                            trigger="click"                            
                            placement='top'
                            overlay={
                                <Popover>                                
                                    <Popover.Content>
                                        The price shown is an estimate. The calculation is based on average market prices. This price range can 
                                        change according to your request or the specifics of your project.
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <span id="disclaimer">disclaimer</span>
                        </OverlayTrigger>  
                    
                        {!this.state.userFilledTheForm &&
                                <ContactForm />
                        }
                        {!!this.state.userFilledTheForm &&
                            <div id='contact-checkbox'>                                
                                <Button 
                                    className="contact-button" 
                                    onClick={this.handleSubmitUserAgreeToBeContactedForm}
                                >
                                    I would like to be contacted
                                </Button>                                   
                            </div> 
                        }
                    </div> 
                }
                {!!this.state.showSuccess && !!this.state.showFinalSuccess &&
                    <div className="show-success"><p>Thank you!</p><p>We will contact you within 24 hours.</p></div>
                }
            </div>
        )
    }
}