import React, {Component} from 'react';
import '../styles/AboutUs.scss';
import Card from 'react-bootstrap/Card';

export default class AboutUs extends Component {
    render() {
        return (
            <div id='about-us'>
                <div className="about-us-text">
                    <h2>ABOUT US</h2>
                    <Card>
                        <Card.Body>
                            <p>We provide one-stop shop solution for your kitchen, washroom and basement renovation needs. We have more than 20 years of collective 
                                experience renovating hi-end and budget oriented projects.
                            </p>
                        </Card.Body>
                    </Card>  
                </div>
                <div className="about-us-container">
                    <div className="column">
                        <i className="fas fa-hard-hat"></i>
                        <h3>All in one renovation solution</h3>
                        <p>Our goal is to make sure that our Clients have a complete understanding of the offering before making the final 
                            decision. Whether it’s a just refacing or a full renovation Benwill is fully prepared to provide you perfect services.
                        </p>
                    </div>
                    <div className="column">
                        <i className="far fa-clock"></i>
                        <h3>Quickest renovation turnaround times</h3>
                        <p>We understand that you want to have your renovation done quickly and we are glad to offer you that. We can do a full 
                            refacing of your cabinets in less than two days and full kitchen replacement in less than two weeks. Ask us if you need any urgent services.
                        </p>
                    </div>
                    <div className="column">
                        <i className="fas fa-award"></i>
                        <h3>Over 400 completed projects!</h3>
                        <p>Benwill always keeps the highest quality of products and services on the market. We have renovated over 400 projects, each designed to meet 
                        all clients requirements.
                        </p>
                    </div>
                    <div className="column">
                        <i className="fas fa-pencil-ruler"></i>
                        <h3>Free no obligation in-home estimate</h3>
                        <p>Our professional designer will take all necessary measurements and discuss with you all the details of your project. Samples of the products 
                            will be shown to you to simplify your materials selection.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}