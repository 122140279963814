import React, {Component} from 'react';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import '../styles/FAQ.scss';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default class FAQ extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <meta name="description" content="FAQ - frequently asked questions." />
                    <meta name="keywords" content="bathroom renovations, kitchen renovations, basement renovations, kitchen remodeling, renovation contractor, bathroom remodelling, kitchen reno, washroom renovations, home renovation contractors, small bathroom renovations, basement contractors, kitchen renovation near me, bathroom contractors near me, home remodeling contractors, home construction companies near me, home renovations near me, kitchen and bathroom renovations, residential contractor, kitchen renovation contractors near me, bedroom renovation, bathroom renovation contractors, kitchen cabinet renovation, basement remodels, small bathroom reno, budget bathroom renovation, kitchen renovation contractor, complete bathroom renovation, kitchen renovation company, basement renovation companies, kitchen renovation companies near me, kitchen construction company, basement bathroom contractors, basement bathroom renovation, bathroom renovations near me, renovation contractors near me, best kitchen remodelers near me, home renovation companies near me, kitchen bathroom renovations, house renovation contractor, bathroom contractors, kitchen contractors, FAQ, faqs, frequently asked questions, find your answer here." />
                    <title>FAQs | Benwill Cabinets</title>
                    <link rel="canonical" href="https://www.benwillcabinets.com/faq" />                  
                    <meta property="og:title" content="FAQs | Benwill Cabinets" />
                    <meta property="og:description" content="" />
                    <meta property="og:url" content="https://www.benwillcabinets.com/faq" />
                    <meta name="twitter:description" content="" />
                    <meta name="twitter:title" content="FAQs | Benwill Cabinets" />
                </Helmet>
                <div id="breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>FAQs</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="faq-container">
                    <h1>Frequently Asked Questions</h1>
                    <p>
                        If you have any specific questions, feel free to <Link to='/#contact'>contact us</Link>.
                    </p>
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    -Is it cheaper to do refacing than to install a new kitchen?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    Refacing costs about <strong>40% - 50% less</strong> than the cost of replacing cabinets in a full kitchen.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    -What is the difference between refinishing and refacing cabinet doors?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <strong>Refinishing</strong> means that you keep all components of your existing cabinetry and simply change the color or finish. This is done through hand sanding or 
                                    chemically stripping the existing finish from the wood, then applying a new paint or stain. (Note: refinishing only works on wood cabinets.)
                                    <br/><strong>Refacing</strong> means that you keep your cabinet boxes, but replace all your cabinet doors and drawer fronts with new ones. This method allows you to 
                                    change the door style, as well as its color. You can also replace cabinet side panels, face frames and moldings so that everything matches.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    -How long does it take to have doors ready before the installation?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    Depends on the profile of the doors and availability of the selected color it may take between <strong>2 and 4 weeks</strong>.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    -How long does a kitchen renovation take?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    From concept to completion, you should budget yourself <strong>3 weeks to 3 months</strong> for your kitchen remodel, depending on the overall scope of 
                                    your project. It’s a considerable investment in terms of time and disruption to your daily life; however, it’s a worthwhile one that not only 
                                    increases the value and functionality of your home, but also the enjoyment factor.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                    -What is the average cost of a kitchen remodel in Toronto?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    The average kitchen renovation costs in Toronto may range from <strong>$14,000</strong> for the most basic renovation and can go up to <strong>$60,000</strong> for a 150 to 400(sq/ft) kitchen.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                    -What is the average cost of a washroom remodel in Toronto?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    Average bathroom (10’x10’) with standard materials will cost <strong>$12,000 – $25,000</strong>. Every bathroom remodeling project is unique and price can be higher 
                                    or lower.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </>
        )
    }
}