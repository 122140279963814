import React from 'react';
import Gallery from "react-photo-gallery";
import { Link } from "react-router-dom";
import "../styles/Project#.scss";
import Lightbox from '@zhyabs1314/react-image-lightbox';
import '@zhyabs1314/react-image-lightbox/style.css'; 
import projectsPhotos from "../utils/projectsPhotos.js";
import queryString from 'query-string';
import { Event } from '../utils/tracking.js';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet';

export default class ProjectDetails extends React.Component {
    constructor(props) {
        super(props);

        const urlParams = queryString.parse(this.props.location.search || '?');
        const projectId = projectsPhotos.find(p => p.project_card_id === Number(urlParams.id)).project_card_id;
        this.state = {
            photoIndex: 0,
            isOpen: false,
            images: projectsPhotos
                    .filter(photo => photo.project_card_id === projectId)
                    .map((photo) => photo.src)
        };
    }
    
    render() {
    const { photoIndex, isOpen, images } = this.state;
    const urlParams = queryString.parse(this.props.location.search || '?');
    const projectId = projectsPhotos.find(p => p.project_card_id === Number(urlParams.id)).project_card_id;
    const galleryPhotos = projectsPhotos.filter(photo => photo.project_card_id === projectId);
    const description = projectsPhotos.filter(disc => disc.project_card_id === projectId)[0].description;
        return (
            <div>
                <Helmet>
                    <title>Project Photos | Benwill Cabinets</title>
                    <link rel="canonical" href={`https://www.benwillcabinets.com/before-and-after-projects/project?id=${projectId}`} />
                    <meta name="description" content={description} />
                    <meta property="og:title" content="Project Photos | Benwill Cabinets" />
                    <meta property="og:description" content={description} />
                    <meta property="og:url" content={`https://www.benwillcabinets.com/before-and-after-projects/project?id=${projectId}`} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:title" content="Project Photos | Benwill Cabinets" />
                </Helmet>
                <div id="breadcrumb">
                    <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="/before-and-after-projects">Before & After</Breadcrumb.Item>
                            <Breadcrumb.Item active>Project Photos</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="project-container" style={{minHeight: "calc(100vh - 44px)"}}>
                    <Gallery photos={galleryPhotos} direction={"column"} onClick={(e, { index }) => this.setState({ isOpen: true, photoIndex: index })} />
                    <div>
                        <Link 
                            to="/before-and-after-projects" 
                            onClick={()=> Event("Go back to all projects button from Project details page", "Go back to all projects button from Project details page was clicked", "project details page")} 
                            id="button"
                        > 
                            Go Back to All Projects
                        </Link>
                    </div>
                    <div className="contact-us">
                        <a 
                            href="/#contact" 
                            id="button" 
                            onClick={()=> Event("Contact Us button from Project details page", "Contact Us button from Project details page was clicked", "project details page")}
                        > 
                            <span> Contact Us </span> 
                        </a>
                    </div>
                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                            }
                            onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                            }
                        />
                    )}
                </div>
            </div>
        );
    }
}