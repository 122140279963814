import React, {Component} from 'react';
import '../styles/ContactForm.scss';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Event } from '../utils/tracking.js';

var Recaptcha = require('react-recaptcha');
let recaptchaInstance;
let recaptchaResponse = "";

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      images: [],
      description: '', 
      name: '', 
      email: '', 
      location: '',
      phoneNumber: '',
      validated: false,
      showForm: true,
      showSuccess: false,
      isVerified: false,
      isRecaptchaExpired: false
    };
  }

  myWidget = window.cloudinary.createUploadWidget({
    // sources: ['local', 'camera'],
    cloudName: 'benwillcabinets', 
    private_cdn: true,
    uploadPreset: 'dghcrvgo',
    thumbnails: '.image_upload',
    clientAllowedFormats: ["png", "gif", "jpeg", "jpg"],
    maxFileSize: 5000000,
    maxImageFileSize: 5000000,
    multiple: true,
    maxFiles: 7,
    moderation: "aws_rek"
    // showPoweredBy: false 
},
    (error, result) => { 
        if (!error && result && result.event === "success") {         
          this.setState({
            images: [...this.state.images, result.info.secure_url]
          })
        }     
    }
)

  recaptchaLoaded = () => {
    console.log('captcha successfully loaded');
  };
    
  verifyCallback = (response) => {
    recaptchaResponse = response;
    if (response) {
      this.setState({
        isVerified: true,
        isRecaptchaExpired: false
      })
    }
  };

  expiredCallback = () => {
    this.setState({ isRecaptchaExpired: true })
    alert("Your recatpcha has expired, please verify again ...");
    recaptchaInstance.reset();
  }

  render() {
    return (
      <div id="contact" className="contactform-container">
        {window.location.pathname !== "/cost-estimator" &&
          <h2>Contact Us</h2>
        }
        {window.location.pathname === "/cost-estimator" &&
          <div className="cost-estimator-part">
            <h3>Happy with this price?</h3>
            <h2>Give us a call or use the form below to get detailed quote</h2>
          </div>
        }
        <div className="flex-container">
          <div className="column1">
            <h3>Get Your Free Estimate</h3>
            <p><a href="tel:416-219-6304" onClick={()=> Event("Call William from Contact us", "Call William from Contact us was clicked", "home page Contact us")}><i className="fas fa-phone contacts"></i>(416) 219-6304</a> - William</p>
            <p><a href="tel:416-576-9111" onClick={()=> Event("Call Ben from Contact us", "Call Ben from Contact us was clicked", "home page Contact us")}><i className="fas fa-phone contacts"></i>(416) 576-9111</a> - Ben</p>
            <p><a href="mailto:info@benwillcabinets.com" target='_blank' rel="noopener noreferrer" onClick={()=> Event("Mail us from Contact us", "Mail us from Contact us was clicked", "home page Contact us")}><i className="far fa-envelope contacts"></i>info@benwillcabinets.com</a></p>
            <div className="follow-us-container"> 
              <p id="follow">Follow us on social media:</p>
              <p><a href="https://www.instagram.com/benwill_cabinets" target='_blank' rel="noopener noreferrer" onClick={()=> Event("Instagram from Contact us", "Instagram from Contact us was clicked", "home page Contact us")}><i className="fab fa-instagram"></i></a>
              <a href="https://www.facebook.com/benwill.cabinets" target='_blank' rel="noopener noreferrer" onClick={()=> Event("Facebook from Contact us", "Facebook from Contact us was clicked", "home page Contact us")}><i className="fab fa-facebook-square"></i></a></p>
            </div>
          </div>
          <div className="column2">
            {
                this.state.showSuccess && <div className="show-success"><p>Thank you!</p><p>Your message has been sent.</p></div>
            }
            {this.state.showForm && 
              <Form 
                style={{margin:"0 auto", textAlign: "left"}} 
                noValidate 
                validated={this.state.validated} 
                onSubmit={this.handleSubmit}
              >   
                <Form.Group controlId="validationCustom01">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                    required
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please input your name.
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustom02">
                    <Form.Label>Phone number</Form.Label>
                    <Form.Control
                    required
                    type="tel"
                    pattern="^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$"
                    placeholder="Phone number"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please input your phone number.
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group controlId="validationCustom03">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                    required
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please input your email.
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationCustom04">
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="City"
                      name="location"
                      value={this.state.location}
                      onChange={(e) => this.setState({ location: e.target.value })}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please input your location.
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows="3"
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={this.state.description}
                    onChange={(e) => this.setState({ description: e.target.value })}
                    />
                </Form.Group>
                <Form.Group 
                  label="Upload" 
                  className="image_upload" 
                  extra="">                                           
                  <Button 
                    onClick={() => this.myWidget.open()} 
                    id="upload_widget" 
                    className="cloudinary-button"
                  >
                    <i className="fas fa-upload"></i> 
                    Click to upload picture
                  </Button>                      
                </Form.Group>
                <Recaptcha
                    ref={e => recaptchaInstance = e}
                    sitekey="6LfSpPIUAAAAAIT7RGSvkwXsawDVXsIZg2hQhlIK"
                    render="explicit"
                    verifyCallback={this.verifyCallback}
                    onloadCallback={this.recaptchaLoaded}
                    expiredCallback={this.expiredCallback}
                />
                <Button 
                  className="contact-button" 
                  type="submit" 
                  onClick={()=> Event("Contact us from Contact us form", "Contact us from Contact us form was clicked", "home page Contact us")}>Contact us</Button>
              </Form> 
            }
          </div>
        </div>
      </div>
    );
  }

  handleSubmit = e => { 
    e.preventDefault()
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
        e.stopPropagation();
        this.setState({validated: true});
    } else if (this.state.isVerified === true && this.state.isRecaptchaExpired === false) {
      this.setState({showForm: false, showSuccess: true});
      
      const captcha = recaptchaResponse;
      fetch('/api/recaptcha', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ captcha }),
      })
      .then(res => res.json())
        .then(data => {
          console.log(data);
        });
      //recaptchaInstance.reset();

      const templateId = 'to_owner';
      this.sendFeedback(templateId, {
        userIP: this.props.userIP || '-', 
        description: this.state.description || '-', 
        name: this.state.name || '-', 
        email: this.state.email || '-', 
        location: this.state.location || '-', 
        phoneNumber: this.state.phoneNumber || '-', 
        uploadedImageUrl: this.state.images || '-'
      })
      .then(() => {
          this.setState({ 
            description: '', 
            name: '', 
            email: '', 
            location: '', 
            phoneNumber: '', 
            showForm: false, 
            showSuccess: true, 
            uploadedImageUrl: '' 
          });
      })
    } else {
      alert("Please verify that you are a human!");
    }
  }
    
  sendFeedback = (templateId, variables) => {
    return window.emailjs.send (
      'benwillcabinets', templateId,
      variables
    )
  }
}
