import React from 'react';

const KitchenRafacingSvg = ({
     style = {},
     fill = "#000",
     width = "100%",
     className = "",
     viewBox = "0 0 64 64"
}) => (
    <svg 
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        className={`svg-icon ${className || ""}`}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill={fill} d="m61 35h-8v-3a1 1 0 0 0 -1-1h-14a1 1 0 0 0 -1 1v3h-10v-3a1 1 0 0 0 -1-1h-14a1 1 0 0 0 -1 1v3h-8a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h1v20a1 1 0 0 0 1 1h54a1 1 0 0 0 1-1v-20h1a1 1 0 0 0 1-1v-4a1 1 0 0 0 -1-1zm-22-2h12v2h-12zm-26 0h12v2h-12zm18 27h-25v-19h25zm27 0h-25v-19h25zm2-21h-56v-2h56z"/>
        <path fill={fill} d="m27 47h2v4h-2z"/>
        <path fill={fill} d="m35 47h2v4h-2z"/>
        <path fill={fill} d="m61 8a1 1 0 0 0 1-1v-4a1 1 0 0 0 -1-1h-58a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h1v7h-1a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h58a1 1 0 0 0 1-1v-4a1 1 0 0 0 -1-1h-1v-7zm-57-4h56v2h-56zm29 11v-7h13v7zm-2-7v7h-12v-7zm-25 0h11v7h-11zm54 11h-56v-2h56zm-2-4h-10v-7h10z"/>
        <path fill={fill} d="m34 10h2v3h-2z"/>
        <path fill={fill} d="m49 10h2v3h-2z"/>
        <path fill={fill} d="m20 10h2v3h-2z"/>
        <path fill={fill} d="m7 10h2v3h-2z"/>
    </svg>
)
export default KitchenRafacingSvg;