import React from 'react';
import Gallery from "react-photo-gallery";
import { Link } from "react-router-dom";
import "../styles/InspirationPhotosCategory.scss";
import Lightbox from '@zhyabs1314/react-image-lightbox';
import '@zhyabs1314/react-image-lightbox/style.css'; 
import { Event } from '../utils/tracking.js';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet';

import axios from 'axios';

const _ = require('lodash')

export default class InspirationPhotosCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            isLoading: false
        }
    };

    componentDidMount() {
        if (window.location.pathname === "/inspiration-photos/kitchen") {
            this.setState({
                isLoading: true,
            }) 
    
            axios.get(`https://res.cloudinary.com/benwillcabinets/image/list/kitchen.json`)
            .then(res => {
                //console.log(res.data.resources);
                this.setState({cloudinaryImages: res.data.resources, isLoading: false});
            });
        } else if (window.location.pathname === "/inspiration-photos/bathroom") {
            this.setState({
                isLoading: true,
            }) 
    
            axios.get(`https://res.cloudinary.com/benwillcabinets/image/list/bathroom.json`)
            .then(res => {
                //console.log(res.data.resources);
                this.setState({cloudinaryImages: res.data.resources, isLoading: false});
            });
        } else if (window.location.pathname === "/inspiration-photos/room") {
            this.setState({
                isLoading: true,
            }) 
    
            axios.get(`https://res.cloudinary.com/benwillcabinets/image/list/room.json`)
            .then(res => {
                //console.log(res.data.resources);
                this.setState({cloudinaryImages: res.data.resources, isLoading: false});
            });
        } else if (window.location.pathname === "/inspiration-photos/wic") {
            this.setState({
                isLoading: true,
            }) 
    
            axios.get(`https://res.cloudinary.com/benwillcabinets/image/list/wic.json`)
            .then(res => {
                //console.log(res.data.resources);
                this.setState({cloudinaryImages: res.data.resources, isLoading: false});
            });
        } else if (window.location.pathname === "/inspiration-photos/wine-cellar") {
            this.setState({
                isLoading: true,
            }) 
    
            axios.get(`https://res.cloudinary.com/benwillcabinets/image/list/wine-cellar.json`)
            .then(res => {
                //console.log(res.data.resources);
                this.setState({cloudinaryImages: res.data.resources, isLoading: false});
            });
        }
    } 

    render(){
    const { isLoading, cloudinaryImages = [] } = this.state;

    cloudinaryImages.map((image, i) => { 
        image.original = `https://res.cloudinary.com/benwillcabinets/image/upload/${image.public_id}.jpg`;
        image.thumbnail = `https://res.cloudinary.com/benwillcabinets/image/upload/${image.public_id}.jpg`;
        image.id = i;
        image.src = `https://res.cloudinary.com/benwillcabinets/image/upload/${image.public_id}.jpeg`;
        image.width = 1;
        image.height = 1;
        return image; 
    })

    let lightBoxImages =  cloudinaryImages.map((photo) => photo.src)
        return (
            <div className="main">
                {window.location.pathname === "/inspiration-photos/kitchen" &&
                <Helmet>
                    <title>Kitchen | Benwill Cabinets</title>
                    <link rel="canonical" href="https://www.benwillcabinets.com/inspiration-photos/kitchen" />
                    <meta name="description" content="Kitchen Renovation Inspiration in the Greater Toronto Area and Hamilton - Upgrade your cooking space with our collection of stunning kitchen renovation inspiration photos. Whether you're in Toronto or Hamilton, our images will provide you with the ideas you need to transform your kitchen into a functional and beautiful space. From modern and sleek updates to cozy and warm transformations, our gallery has something for every style and taste. Browse our collection today and get inspired for your next kitchen renovation project." />
                    <meta name="keywords" content="kitchen renovation inspiration Toronto, cooking space Greater Toronto Area, stunning collection Hamilton, modern updates Toronto, cozy transformations Hamilton, functional kitchen Greater Toronto Area, beautiful space Hamilton, renovation project Toronto, inspiration photos Greater Toronto Area, kitchen renovation Hamilton." />
                    <meta property="og:title" content="Kitchen | Benwill Cabinets" />
                    <meta property="og:description" content="Kitchen Renovation Inspiration in the Greater Toronto Area and Hamilton - Upgrade your cooking space with our collection of stunning kitchen renovation inspiration photos. Whether you're in Toronto or Hamilton, our images will provide you with the ideas you need to transform your kitchen into a functional and beautiful space. From modern and sleek updates to cozy and warm transformations, our gallery has something for every style and taste. Browse our collection today and get inspired for your next kitchen renovation project." />
                    <meta property="og:url" content="https://www.benwillcabinets.com/inspiration-photos/kitchen" />
                    <meta name="twitter:description" content="Kitchen Renovation Inspiration in the Greater Toronto Area and Hamilton - Upgrade your cooking space with our collection of stunning kitchen renovation inspiration photos. Whether you're in Toronto or Hamilton, our images will provide you with the ideas you need to transform your kitchen into a functional and beautiful space. From modern and sleek updates to cozy and warm transformations, our gallery has something for every style and taste. Browse our collection today and get inspired for your next kitchen renovation project." />
                    <meta name="twitter:title" content="Kitchen | Benwill Cabinets" />
                </Helmet>
                }
                {window.location.pathname === "/inspiration-photos/bathroom" &&
                <Helmet>
                    <title>Bathroom | Benwill Cabinets</title>
                    <link rel="canonical" href="https://www.benwillcabinets.com/inspiration-photos/bathroom" />
                    <meta name="description" content="Transform Your Bathroom into a Haven with Our Collection of Inspiring Renovation Photos - Are you looking to renovate your bathroom in the Greater Toronto Area or Hamilton? Look no further than our stunning collection of bathroom renovation inspiration photos. From modern and sleek updates to cozy and warm transformations, our images will provide you with the ideas you need to create the functional and beautiful space you've always wanted. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next bathroom renovation project." />
                    <meta name="keywords" content="bathroom renovation inspiration Toronto, stunning collection Greater Toronto Area, modern updates Hamilton, cozy transformations Toronto, functional bathroom Greater Toronto Area, beautiful space Hamilton, renovation project Toronto, inspiration photos Hamilton, bathroom renovation Hamilton, bathroom design Toronto." />
                    <meta property="og:title" content="Bathroom | Benwill Cabinets" />
                    <meta property="og:description" content="Transform Your Bathroom into a Haven with Our Collection of Inspiring Renovation Photos - Are you looking to renovate your bathroom in the Greater Toronto Area or Hamilton? Look no further than our stunning collection of bathroom renovation inspiration photos. From modern and sleek updates to cozy and warm transformations, our images will provide you with the ideas you need to create the functional and beautiful space you've always wanted. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next bathroom renovation project." />
                    <meta property="og:url" content="https://www.benwillcabinets.com/inspiration-photos/bathroom" />
                    <meta name="twitter:description" content="Transform Your Bathroom into a Haven with Our Collection of Inspiring Renovation Photos - Are you looking to renovate your bathroom in the Greater Toronto Area or Hamilton? Look no further than our stunning collection of bathroom renovation inspiration photos. From modern and sleek updates to cozy and warm transformations, our images will provide you with the ideas you need to create the functional and beautiful space you've always wanted. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next bathroom renovation project." />
                    <meta name="twitter:title" content="Bathroom | Benwill Cabinets" />
                </Helmet>
                }
                {window.location.pathname === "/inspiration-photos/room" &&
                <Helmet>
                    <title>Living Room & Bedroom | Benwill Cabinets</title>
                    <link rel="canonical" href="https://www.benwillcabinets.com/inspiration-photos/room" />
                    <meta name="description" content="Transform Your Living Room and Bedroom into Your Dream Space - Looking for inspiration for your next renovation project in the Greater Toronto Area or Hamilton? Our collection of stunning living room and bedroom renovation inspiration photos will provide you with the ideas you need to turn your space into the haven you've always wanted. From modern and sleek updates to cozy and warm transformations, our images will spark your creativity and help bring your vision to life. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next renovation project." />
                    <meta name="keywords" content="living room renovation inspiration Toronto, bedroom renovation inspiration Hamilton, stunning collection Greater Toronto Area, modern updates Toronto, cozy transformations Hamilton, functional living room Greater Toronto Area, beautiful bedrooms Hamilton, renovation project Toronto, inspiration photos Hamilton, living room renovation Hamilton, bedroom design Toronto." />
                    <meta property="og:title" content="Living Room & Bedroom | Benwill Cabinets" />
                    <meta property="og:description" content="Transform Your Living Room and Bedroom into Your Dream Space - Looking for inspiration for your next renovation project in the Greater Toronto Area or Hamilton? Our collection of stunning living room and bedroom renovation inspiration photos will provide you with the ideas you need to turn your space into the haven you've always wanted. From modern and sleek updates to cozy and warm transformations, our images will spark your creativity and help bring your vision to life. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next renovation project." />
                    <meta property="og:url" content="https://www.benwillcabinets.com/inspiration-photos/room" />
                    <meta name="twitter:description" content="Transform Your Living Room and Bedroom into Your Dream Space - Looking for inspiration for your next renovation project in the Greater Toronto Area or Hamilton? Our collection of stunning living room and bedroom renovation inspiration photos will provide you with the ideas you need to turn your space into the haven you've always wanted. From modern and sleek updates to cozy and warm transformations, our images will spark your creativity and help bring your vision to life. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next renovation project." />
                    <meta name="twitter:title" content="Living Room & Bedroom | Benwill Cabinets" />
                </Helmet>
                }
                {window.location.pathname === "/inspiration-photos/wic" &&
                <Helmet>
                    <title>Walk-In Closet | Benwill Cabinets</title>
                    <link rel="canonical" href="https://www.benwillcabinets.com/inspiration-photos/wic" />
                    <meta name="description" content="Transform Your Walk-in Closet into Your Personal Oasis - Are you looking to renovate your walk-in closet in the Greater Toronto Area or Hamilton? Look no further than our collection of inspiring renovation photos. From modern and sleek updates to cozy and warm transformations, our images will provide you with the ideas you need to create a functional and beautiful space that meets all of your storage needs. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next walk-in closet renovation project." />
                    <meta name="keywords" content="walk-in closet renovation inspiration Toronto, stunning collection Greater Toronto Area, modern updates Hamilton, cozy transformations Toronto, functional walk-in closet Greater Toronto Area, beautiful closets Hamilton, renovation project Toronto, inspiration photos Hamilton, walk-in closet renovation Hamilton, closet design Toronto." />
                    <meta property="og:title" content="Walk-In Closet | Benwill Cabinets" />
                    <meta property="og:description" content="Transform Your Walk-in Closet into Your Personal Oasis - Are you looking to renovate your walk-in closet in the Greater Toronto Area or Hamilton? Look no further than our collection of inspiring renovation photos. From modern and sleek updates to cozy and warm transformations, our images will provide you with the ideas you need to create a functional and beautiful space that meets all of your storage needs. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next walk-in closet renovation project." />
                    <meta property="og:url" content="https://www.benwillcabinets.com/inspiration-photos/wic" />
                    <meta name="twitter:description" content="Transform Your Walk-in Closet into Your Personal Oasis - Are you looking to renovate your walk-in closet in the Greater Toronto Area or Hamilton? Look no further than our collection of inspiring renovation photos. From modern and sleek updates to cozy and warm transformations, our images will provide you with the ideas you need to create a functional and beautiful space that meets all of your storage needs. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next walk-in closet renovation project." />
                    <meta name="twitter:title" content="Walk-In Closet | Benwill Cabinets" />
                </Helmet>
                }
                {window.location.pathname === "/inspiration-photos/wine-cellar" &&
                <Helmet>
                    <title>Wine Cellar | Benwill Cabinets</title>
                    <link rel="canonical" href="https://www.benwillcabinets.com/inspiration-photos/wine-cellar" />
                    <meta name="description" content="Transform Your Wine Cellar into a Stunning Collection Space - Are you looking to renovate your wine cellar in the Greater Toronto Area or Hamilton? Our collection of inspiring renovation photos will provide you with the ideas you need to turn your space into a functional and beautiful collection area. From modern and sleek updates to cozy and warm transformations, our images will spark your creativity and help bring your vision to life. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next wine cellar renovation project." />
                    <meta name="keywords" content="wine cellar renovation inspiration Toronto, stunning collection Greater Toronto Area, modern updates Hamilton, cozy transformations Toronto, functional wine cellar Greater Toronto Area, beautiful wine cellars Hamilton, renovation project Toronto, inspiration photos Hamilton, wine cellar renovation Hamilton, wine cellar design Toronto." />
                    <meta property="og:title" content="Wine Cellar | Benwill Cabinets" />
                    <meta property="og:description" content="Transform Your Wine Cellar into a Stunning Collection Space - Are you looking to renovate your wine cellar in the Greater Toronto Area or Hamilton? Our collection of inspiring renovation photos will provide you with the ideas you need to turn your space into a functional and beautiful collection area. From modern and sleek updates to cozy and warm transformations, our images will spark your creativity and help bring your vision to life. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next wine cellar renovation project." />
                    <meta property="og:url" content="https://www.benwillcabinets.com/inspiration-photos/wine-cellar" />
                    <meta name="twitter:description" content="Transform Your Wine Cellar into a Stunning Collection Space - Are you looking to renovate your wine cellar in the Greater Toronto Area or Hamilton? Our collection of inspiring renovation photos will provide you with the ideas you need to turn your space into a functional and beautiful collection area. From modern and sleek updates to cozy and warm transformations, our images will spark your creativity and help bring your vision to life. Whether you're in Toronto or Hamilton, our gallery has something for every style and taste. Browse our collection today and get inspired for your next wine cellar renovation project." />
                    <meta name="twitter:title" content="Wine Cellar | Benwill Cabinets" />
                </Helmet>
                }
                <div id="breadcrumb">
                    <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="/inspiration-photos">Inspiration Ideas</Breadcrumb.Item>
                            {window.location.pathname === "/inspiration-photos/kitchen" &&
                                <Breadcrumb.Item active>Kitchen</Breadcrumb.Item>
                            }
                            {window.location.pathname === "/inspiration-photos/bathroom" &&
                                <Breadcrumb.Item active>Bathroom</Breadcrumb.Item>
                            }
                            {window.location.pathname === "/inspiration-photos/room" &&
                                <Breadcrumb.Item active>Living Room & Bedroom</Breadcrumb.Item>
                            }
                            {window.location.pathname === "/inspiration-photos/wic" &&
                                <Breadcrumb.Item active>Walk-In Closet</Breadcrumb.Item>
                            }
                            {window.location.pathname === "/inspiration-photos/wine-cellar" &&
                                <Breadcrumb.Item active>Wine Cellar</Breadcrumb.Item>
                            }
                    </Breadcrumb>
                </div>
                <div className="gallery-container">
                    {!isLoading && (cloudinaryImages || []).length > 0 &&     
                    <>     
                        <Gallery 
                            photos={cloudinaryImages} 
                            direction={"column"} 
                            onClick={(e, { index }) => this.setState({ isOpen: true, photoIndex: index })}                            
                        />
                        <div className='go-back'>
                            <Link 
                                to="/inspiration-photos" 
                                onClick={()=> Event(`${window.location.pathname} page`, `Go back to All Categories button from ${window.location.pathname} page was clicked`, `${window.location.pathname} page Go back to All Categories button`)} 
                                id="button"
                            > 
                                Go Back to All Categories
                            </Link>
                        </div>
                        <div className="contact-us">
                            <a 
                                href="/#contact" 
                                id="button" 
                                onClick={()=> Event(`${window.location.pathname} page`, `Contact Us button from ${window.location.pathname} page was clicked`, `${window.location.pathname} page Contact Us button`)}
                            > 
                                <span> Contact Us </span> 
                            </a>
                        </div>
                    </>
                    }
                </div>
                {this.state.isOpen && 
                    <Lightbox                            
                        mainSrc={_.uniq(lightBoxImages)[this.state.photoIndex]}
                        nextSrc={_.uniq(lightBoxImages)[(this.state.photoIndex + 1) % _.uniq(lightBoxImages).length]}
                        prevSrc={_.uniq(lightBoxImages)[(this.state.photoIndex + _.uniq(lightBoxImages).length - 1) % _.uniq(lightBoxImages).length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + _.uniq(lightBoxImages).length - 1) % _.uniq(lightBoxImages).length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % _.uniq(lightBoxImages).length,
                        })
                        }
                    />
                }
                {!!isLoading &&
                <div className='loading-container'>
                    Loading...
                </div>
                } 
            </div>
        );
    }
}