import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../styles/InspirationPhotos.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet';
import { Event } from '../utils/tracking.js';

export default class InspirationPhotos extends Component {    
    render() {
        return (
            <div>
                <Helmet>
                    <title>Inspiration Ideas | Benwill Cabinets</title>
                    <link rel="canonical" href="https://www.benwillcabinets.com/inspiration-photos" />
                    <meta name="description" content="Transform Your Living Space in Greater Toronto Area and Hamilton with Our Home Renovation Inspiration Ideas - Looking for inspiration for your next renovation project? Our stunning collection of renovation photos will spark your creativity and help bring your vision to life. From modern and sleek updates to cozy and warm transformations, we have something for every style and taste. Whether you're in Toronto or Hamilton, our inspiring images will provide you with the ideas you need to turn your house into the home of your dreams. Browse our gallery today and get started on your renovation journey." />
                    <meta name="keywords" content="home renovation inspiration Toronto, living space Toronto, stunning collection Greater Toronto Area, gallery Hamilton, inspiring photos Greater Toronto Area, kitchen remodeling Hamilton, bathroom updates Greater Toronto Area, home makeover Hamilton, dream renovation project Greater Toronto Area, creative vision Toronto, modern updates Hamilton, cozy transformations Greater Toronto Area." />
                    <meta property="og:title" content="Inspiration Ideas | Benwill Cabinets" />
                    <meta property="og:description" content="Transform Your Living Space in Greater Toronto Area and Hamilton with Our Home Renovation Inspiration Ideas - Looking for inspiration for your next renovation project? Our stunning collection of renovation photos will spark your creativity and help bring your vision to life. From modern and sleek updates to cozy and warm transformations, we have something for every style and taste. Whether you're in Toronto or Hamilton, our inspiring images will provide you with the ideas you need to turn your house into the home of your dreams. Browse our gallery today and get started on your renovation journey." />
                    <meta property="og:url" content="https://www.benwillcabinets.com/inspiration-photos" />
                    <meta name="twitter:description" content="Transform Your Living Space in Greater Toronto Area and Hamilton with Our Home Renovation Inspiration Ideas - Looking for inspiration for your next renovation project? Our stunning collection of renovation photos will spark your creativity and help bring your vision to life. From modern and sleek updates to cozy and warm transformations, we have something for every style and taste. Whether you're in Toronto or Hamilton, our inspiring images will provide you with the ideas you need to turn your house into the home of your dreams. Browse our gallery today and get started on your renovation journey." />
                    <meta name="twitter:title" content="Inspiration Ideas | Benwill Cabinets" />
                </Helmet>
                <div id="breadcrumb">
                    <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Inspiration Ideas</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="inspiration-photos-container">
                <h2>Inspiring Home Renovation Ideas</h2>
                <p>
                    Transform Your Home into <span>Your Dream Space</span> with Our Collection of <span>Inspiring Renovation Photos</span>.
                </p>
                <p>
                    From kitchen updates to bathroom transformations, our images will provide you with the ideas you need to create the <span>functional</span> and 
                    <span> beautiful</span> space you've always wanted. Browse our collection today and get inspired for your next home renovation project. 
                    Start turning your house into your dream home now!
                </p>
                    <Container>  
                        <Row>                            
                            <Link 
                                to="/inspiration-photos/kitchen"
                                onClick={()=> Event(`${window.location.pathname} page`, `Kitchen card from ${window.location.pathname} page was clicked`, `${window.location.pathname} page Kitchen`)} 
                            >
                                <Col lg={true}>
                                    <Card 
                                        style={{ width: '20rem' }}
                                    >
                                        <Card.Img 
                                            variant="top" 
                                            src="https://res.cloudinary.com/benwillcabinets/image/upload/inspiration_photos/kitchen/4FEDD2C8-CD18-4C59-A2AD-D39D6BBE9332.png"                                                 
                                        />
                                        <Card.Body>
                                            <Card.Text>
                                                Kitchen
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Link>
                            <Link 
                                to="/inspiration-photos/bathroom"
                                onClick={()=> Event(`${window.location.pathname} page`, `Bathroom card from ${window.location.pathname} page was clicked`, `${window.location.pathname} page Bathroom`)} 
                            >
                                <Col lg={true}>
                                    <Card 
                                        style={{ width: '20rem' }}
                                    >
                                        <Card.Img 
                                            variant="top" 
                                            src="https://res.cloudinary.com/benwillcabinets/image/upload/inspiration_photos/bathroom/A613F37A-4C98-4D60-81AE-F331891AA7CD.png"                                                 
                                        />
                                        <Card.Body>
                                            <Card.Text>
                                                Bathroom
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Link>
                            <Link 
                                to="/inspiration-photos/room"
                                onClick={()=> Event(`${window.location.pathname} page`, `Living Room & Bedroom card from ${window.location.pathname} page was clicked`, `${window.location.pathname} page Living Room & Bedroom`)} 
                            >
                                <Col lg={true}>
                                    <Card 
                                        style={{ width: '20rem' }}
                                    >
                                        <Card.Img 
                                            variant="top" 
                                            src="https://res.cloudinary.com/benwillcabinets/image/upload/inspiration_photos/living-room-bedroom/56D2CBCF-E615-42E3-9362-7F11F3132724.png"                                                 
                                        />
                                        <Card.Body>
                                            <Card.Text>
                                                Living Room & Bedroom
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Link>
                            <Link 
                                to="/inspiration-photos/wic"
                                onClick={()=> Event(`${window.location.pathname} page`, `Walk-In Closet card from ${window.location.pathname} page was clicked`, `${window.location.pathname} page Walk-In Closet`)} 
                            >
                                <Col lg={true}>
                                    <Card 
                                        style={{ width: '20rem' }}
                                    >
                                        <Card.Img 
                                            variant="top" 
                                            src="https://res.cloudinary.com/benwillcabinets/image/upload/inspiration_photos/walk-in-closet/PHOTO-2022-12-25-21-09-16.jpg"                                                 
                                        />
                                        <Card.Body>
                                            <Card.Text>
                                                Walk-In Closet
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Link>
                            <Link 
                                to="/inspiration-photos/wine-cellar"
                                onClick={()=> Event(`${window.location.pathname} page`, `Wine Cellar card from ${window.location.pathname} page was clicked`, `${window.location.pathname} page Wine Cellar`)} 
                            >
                                <Col lg={true}>
                                    <Card 
                                        style={{ width: '20rem' }}
                                    >
                                        <Card.Img 
                                            variant="top" 
                                            src="https://res.cloudinary.com/benwillcabinets/image/upload/inspiration_photos/wine-cellar/cbffdcccca8bfc096eda369e5fa40f3b-xxlarge_ygljb6.jpg"                                                 
                                        />
                                        <Card.Body>
                                            <Card.Text>
                                                Wine Cellar
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Link>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}