import React, {Component} from 'react';
import '../styles/OurProcess.scss';
import config from 'react-reveal/globals';
import LightSpeed from 'react-reveal/LightSpeed';

export default class OurProcess extends Component {
    render() {
        config({ ssrFadeout: true });
        return (
            <div className='main-container'>
                <h2>OUR PROCESS</h2>
                <div className='our-process-container'>
                    <div className="flex-container">
                    <LightSpeed left><p>- We scope the line of work that is required and take accurate measurements of existing layouts.</p></LightSpeed>
                    <LightSpeed right><p>- We provide an estimate to the customer based on the scope of work requested by client.</p></LightSpeed>
                        <LightSpeed left><p>- When the customer is in agreement with the estimate, they will then return the signed estimate/agreement indicating that work can commence.</p></LightSpeed>
                        <LightSpeed right><p>- 50% down payment will be required indicating seriousness and commitment in order to seal the contract between both parties. The balance will be due upon completion of work.</p></LightSpeed>
                        <LightSpeed left><p>- References will be gladly given upon request.</p></LightSpeed>
                    </div>
                </div>
            </div>
        )
    }
}