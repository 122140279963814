import React, {Component} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import '../styles/NavBar.scss';
import { Event } from '../utils/tracking.js';

export default class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <nav id="content">
                <Navbar expand="xl" collapseOnSelect={true}>
                        <Navbar.Brand 
                            href={window.location.pathname === '/' ? "#top" : "/"} 
                            onClick={()=> Event(`${window.location.pathname} page Logo from NavBar`, "Logo from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                        >
                            Benwill
                        </Navbar.Brand>
                        <span 
                            className="call"
                        >
                            Call Us! 
                            <a 
                                href="tel:416-219-6304" 
                                onClick={()=> Event(`${window.location.pathname} page Call us from NavBar`, "Call us from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                            >
                                <span> (416) 219-6304</span>
                            </a>
                        </span>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav>
                            <Nav.Link 
                                href={window.location.pathname === '/' ? "#top" : "/"} 
                                onClick={()=> Event("Home from NavBar", "Home from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                            >
                                Home
                            </Nav.Link>
                            <Nav.Link 
                                href={window.location.pathname === '/' ?"#our-services" : "/#our-services"} 
                                onClick={()=> Event("Our services from NavBar", "Our services from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                            >
                                Our Services
                            </Nav.Link>
                            <Nav.Link 
                                href={window.location.pathname === '/' ? "#gallery" : "/#gallery"} 
                                onClick={()=> Event("Gallery from NavBar", "Gallery from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                            >
                                Gallery
                            </Nav.Link>
                            <Nav.Link 
                                href="/before-and-after-projects" 
                                onClick={()=> Event("Before & after projects from NavBar", "Before & after projects from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                            >
                                Before & After
                            </Nav.Link>
                            <Nav.Link 
                                href="/inspiration-photos" 
                                onClick={()=> Event("Inspiration Ideas from NavBar", "Inspiration Ideas from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                            >
                                Inspiration Ideas
                            </Nav.Link>                            
                            <Nav.Link 
                                href={window.location.pathname === '/' ? "#testimonials" : "/#testimonials"} 
                                onClick={()=> Event("Testimonials from NavBar", "Testimonials from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                            >
                                Testimonials
                            </Nav.Link>
                            <Nav.Link 
                                href="/cost-estimator" 
                                onClick={()=> Event("/cost-estimator from NavBar", "/cost-estimator from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                            >
                                Online Estimator
                            </Nav.Link>
                            <Nav.Link 
                                href={window.location.pathname === '/' ? "#contact" : "/#contact"} 
                                onClick={()=> Event("Contact us from NavBar", "Contact us from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                            >
                                Contact Us
                            </Nav.Link>
                            <Nav.Link   
                                href="/warranty" 
                                onClick={()=> Event("Warranty from NavBar", "Warranty from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                            >
                                Warranty
                            </Nav.Link>
                            <Nav.Link 
                                href="/faq" 
                                onClick={()=> Event("FAQ from NavBar", "FAQ from NavBar was clicked", `${window.location.pathname} page NavBar`)}
                            >
                                FAQs
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </nav>
        )
    }
}